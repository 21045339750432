<template>
  <div>
    <div class="bgs-wrap">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2"  v-if="subdomain=='waterski'">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">{{ getSiteProps('general.site_name') }}</h1>
            </div>
            <div class="col-sm-6"></div>           
          </div>       
        </div>
        <div class="row rm-p-m" v-if="loading">
            <div class="col-md-12 text-center p-3">
                <i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
        </div>
        <div class="row rm-p-m" v-else>
          <div class="col-md-12">
            <div v-if="subdomain == 'waterski' || profileData.info.backgroundScreenCount==0 || secondBackGround==true" class="clubrenewal" >
              <div class="tabbable">
                <div class="h-steps ">
                  <section v-if="wiz_step == 1">
                    <ul class="header-navigation">
                      <li v-if="wiz_step == 1 && subdomain == 'waterski'">
                        <span class="header-steps current-item">Choose Option</span>
                      </li>
                      <li v-if="wiz_step == 1 && subdomain == 'shooting'">
                        <span class="header-steps current-item">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps">Application Information</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li><span class="header-steps">Confirm</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 2">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps current-item">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps">Application Information</span>
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li><span class="header-steps">Confirm</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 3">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps current-item"
                          >Applicant Information</span
                        >
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li><span class="header-steps">Confirm</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 9">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed"
                        >Applicant Information</span
                        >
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps current-item">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li><span class="header-steps">Confirm</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 10">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps  completed"
                        >Applicant Information</span
                        >
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps completed">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps current-item">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li><span class="header-steps">Confirm</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 4">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps  completed"
                          >Applicant Information</span
                        >
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps completed">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps completed">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps current-item">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li><span class="header-steps">Confirm</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 5">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed"
                          >Applicant Information</span
                        >
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps completed">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps completed">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps completed">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps current-item" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps current-item" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li>
                        <span class="header-steps">Confirm</span>
                      </li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 6">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed"
                          >Applicant Information</span
                        >
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps completed">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps completed">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps completed">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps completed" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps completed" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps current-item">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps current-item">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps">Payment</span></li>
                      <li>
                        <span class="header-steps">Confirm</span>
                      </li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 7">
                    <ul class="header-navigation">
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Choose Option</span>
                      </li>
                      <li v-if="subdomain == 'shooting'">
                        <span class="header-steps completed">Background Screening Details</span>
                      </li>
                      <li v-if="subdomain == 'waterski'">
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed">Applicant Information</span>
                      </li>
                      <li v-if="subdomain == 'shooting' && form.backgroundScreeningType==2">
                        <span class="header-steps completed">Additional Information</span>
                      </li>
                      <li v-if="(form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski')">
                        <span class="header-steps completed">International Address</span>
                      </li>
                      <li v-if="subdomain=='shooting'">
                        <span class="header-steps completed">Disclosure</span>
                      </li>
                      <li>
                        <span class="header-steps completed" v-if="subdomain === 'shooting'">State & Criminal History</span>
                        <span class="header-steps completed" v-else>State and City Notices</span>
                      </li>
                      <li v-if="subdomain=='waterski'"><span class="header-steps completed">Sign</span></li>
                      <li v-if="subdomain=='shooting'"><span class="header-steps completed">Consent</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='waterski'"><span class="header-steps current-item">Pay</span></li>
                      <li v-show="groupCommittee.payment == true" v-if="subdomain=='shooting'"><span class="header-steps current-item">Payment</span></li>
                      <li>
                        <span class="header-steps">Confirm</span>
                      </li>
                    </ul>
                  </section>

                </div>
              </div>
            </div>
            <div v-if="groupCommittee.screening == true && bgScreenEnable && ((internationalBackgroundScreen == null && (backgroundScreen == null || backgroundScreen.status == 5)) || secondBackGround==true)">
              <div v-if="wiz_step == 1 && subdomain == 'waterski'" class="donation-view">
                <section>
                  <div class="card donation">
                    <div class="card-header register-title">
                      <h3>Choose Option</h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 pt-3 pb-2">
                          <div class="row">
                            <div class="col-md-10 pt-1 p-2 bd-highlight">
                              <h4 class="mt-2">Click Here if you have lived in CANADA in the past 7 years for 6 months or longer.</h4>
                            </div>
                            <div class="col-md-2 text-right">
                              <div class=" p-2 bd-highlight">
                                <button type="button" v-if="prices.international_background_screening" class="btn cancelbtn btn-previious" :class="form.lived_status == 2 ? 'ncsi-btn-selected' : 'btn-outline-primary'" @click="livedStatus(2)">
                                  <i class="fa fa-check" v-show="form.lived_status == 2"></i> {{ form.lived_status == 2 ? 'Applied' : 'Apply'}}
                                </button>
                                <button class="btn cancelbtn btn-previious" disabled v-else>
                                  <i class="fa fa-check" v-show="form.lived_status == 2"></i> {{ form.lived_status == 2 ? 'Applied' : 'Apply'}}
                                </button>
                              </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-10  p-2 bd-highlight">
                               <h4 class="mt-2">Click Here if you have LIVED OUTSIDE THE US (EXCLUDING CANADA) in the past 7 years for 6 months or longer.</h4>
                            </div>
                        <div class="col-md-2 text-right">
                            <div class=" p-2 bd-highlight">
                               <button type="button" v-if="prices.background_screening_canada" class="btn cancelbtn btn-previious" :class="form.lived_status == 3 ? 'ncsi-btn-selected' : 'btn-outline-primary'" @click="livedStatus(3)">
                                 <i class="fa fa-check" v-show="form.lived_status == 3"></i> {{ form.lived_status == 3 ? 'Applied' : 'Apply'}}
                                </button>
                                <button class="btn cancelbtn btn-previious" disabled v-else>
                                  <i class="fa fa-check" v-show="form.lived_status == 3"></i> {{ form.lived_status == 3 ? 'Applied' : 'Apply'}}
                                </button>
                            </div>
                        </div>
                          </div>
                          
                        </div>
                          <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-10 p-2 bd-highlight">
                            <h4 class="mt-2">Click Here if you have ONLY lived in the US for the past 7 years.</h4>
                            </div>
                            <div class="col-md-2 text-right">
                            <div class=" p-2 bd-highlight">
                            <button type="button" v-if="prices.background_screening" class="btn cancelbtn btn-previious" :class="form.lived_status == 1 ? 'ncsi-btn-selected' : 'btn-outline-primary'" @click="livedStatus(1)">
                              <i class="fa fa-check" v-show="form.lived_status == 1"></i> {{ form.lived_status == 1 ? 'Applied' : 'Apply'}}
                            </button>
                            <button class="btn cancelbtn btn-previious" disabled v-else>
                              <i class="fa fa-check" v-show="form.lived_status == 1"></i> {{ form.lived_status == 1 ? 'Applied' : 'Apply'}}
                            </button>
                            </div>
                            </div>
                          </div>
                          </div>
                        <div class="col-md-12">
                          <blockquote>
                            These forms are processed on behalf of {{ getSiteProps("general.site_name") }}.
                            <a href="/dist/img/profile/backimg/NCSI-auth-form-USAWS.pdf" target="_blank">Click Here</a> to view the Authorization Document.
                          </blockquote>
                        </div>
                        <span class="text-sm text-danger">{{ errors.status}}</span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div v-if="wiz_step == 1 && subdomain == 'shooting'" class="donation-view">
                <section>
                  <div class="card donation">
                    <div class="card-header register-title">
                      <h3>Background Screening Details</h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 pt-3 pb-2">
                          <div class="row">
                            <div class="col-md-2 pt-1 p-2 bd-highlight">
                              <strong class="mt-2">Type<sup class="text-danger">*</sup></strong>
                            </div>
                            <div class="col-md-10 mt-2">
                              <select class="form-control" id="backgroundScreeningType" name="backgroundScreeningType" @change="backgroundScreeningType" v-model="form.backgroundScreeningType">
                                <option value="1" v-if="prices.background_screening">Background Screening</option>
                                <option value="2" v-if="prices.international_background_screening">International Background Screening</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.background_screening_type}}</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-2 pt-1 p-2 bd-highlight">
                            </div>
                            <div class="col-md-10 mt-2">Candidates not currently living in the U.S. or are not U.S. Citizens must apply for an International Background Screening.</div>
                          </div>
                          <div class="row">
                            <div class="col-md-2 pt-1 p-2 bd-highlight">
                            </div>
                            <div class="col-md-10 mt-2"><span class="text-danger">NOTE:</span> THE AMOUNT THAT IS ADDED TO THE CART WILL VARY SUBJECT TO THE OPTIONS SELECTED ON THE FORM.</div>
                          </div>
                        </div>
                        <div class="backGroundScreen" v-if="backgroundType">
                        <div class="col-md-12 pt-3 pb-2">
                          <div class="row">
                            <div class="col-md-2 pt-1 p-2 bd-highlight">
                              <strong class="mt-2">Cost</strong>
                            </div>
                            <div class="col-md-10 mt-2">${{ prices.background_screening || '0.00' }}</div>
                          </div>
                        </div>
                        <div class="col-md-12 pt-3 pb-2">
                          <div class="row">
                            <div class="col-md-2 pt-1 p-2 bd-highlight">
                              <strong class="mt-2">Renewal Period</strong>
                            </div>
                            <div class="col-md-10 mt-2">24 months</div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-2 p-2 bd-highlight">
                              <strong class="mt-2">Approval Information: </strong>
                            </div>
                            <div class="col-md-10">
                              <span class="mt-2">This item requires payment followed by approval from an administrator. Once you have completed your payment one of our administrators will check the validity of this item before approving the request to add it to your profile. Upon conclusion of our checks, if successful the status of this item will change to Complete. In cases where applications are unsuccessful (displaying an 'Incomplete' status), please use the support feature for more information. </span>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="backGroundScreen" v-else>
                          <div class="col-md-12 pt-3 pb-2">
                            <label for="internationalAddr" class="form-label"><strong>Have you lived outside of the United States for six consecutive months in any one country,
                              during the last 7 years?</strong><sup class="text-danger">*</sup></label>
                            <input type="radio" id="internationalAddr-1" name="internationalAddr-1"  value="1" v-model="form.internationalAddr" @change="internationalAddresses">
                            <label for="internationalAddr-1">Yes</label>
                            <input class="ml-2" type="radio" id="internationalAddr-2" name="internationalAddr-1" value="2" v-model="form.internationalAddr" @change="showInternationalModal()">
                            <label for="internationalAddr-2">No</label>
                            <span class="text-sm text-danger">{{ errors.internationalAddr }}</span>
                          </div>
                          <div class="col-md-12 pt-3 pb-2">
                            <div class="row">
                              <div class="col-md-2 pt-1 p-2 bd-highlight">
                                <strong class="mt-2">Cost</strong>
                              </div>
                              <div class="col-md-10 mt-2">${{ prices.international_background_screening || '0.00' }}</div>
                            </div>
                          </div>
                          <div class="col-md-12 pt-3 pb-2">
                            <div class="row">
                              <div class="col-md-2 pt-1 p-2 bd-highlight">
                                <strong class="mt-2">Renewal Period</strong>
                              </div>
                              <div class="col-md-10 mt-2">24 months</div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-2 p-2 bd-highlight">
                                <strong class="mt-2">Approval Information: </strong>
                              </div>
                              <div class="col-md-10">
                                <span class="mt-2">Initially the status of this item will display as 'Pending Approval'. This is because one of our administrators is required to check the validity of this item before approving the request to add it to your profile. Upon conclusion of our checks, the items status will change to either 'Complete' or 'Incomplete'. In cases where applications are unsuccessful (displaying an 'Incomplete' status), please use the support feature for more information. </span>
                              </div>
                            </div>
                          </div>
                          <p></p>

                          </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div v-if="wiz_step == 2 && subdomain == 'waterski'" class="donation-view">
                <section>
                  <div class="card donation">
                    <div class="card-header register-title">
                      <h3>Welcome</h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <p>
                            Thank you for your interest in USA Water Ski and
                            Wake Sports!
                          </p>
                          <p>
                            USA Water Ski and Wake Sports have adopted the
                            following USOPC criteria for the exclusion of
                            participation:
                          </p>
                          <p>
                            A reportable record or disclosure that contains a
                            disposition or resolution of a criminal proceeding,
                            other than an adjudication of not guilty, for any of
                            the below criminal offenses will be flagged, meaning
                            it does not meet the initial screening criteria and
                            the individual will be subject to USA Water Ski & Wake Sports (USA-WSWS) policies and procedures to determine eligibility:
                          </p>

                          <ul class="grassrootul backscreen-group">
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong>CRL-1 </strong> Any felony<br>
                                  Defined as all crimes punishable by greater
                                  than one year in jail or prison, regardless of
                                  how characterized by jurisdiction. If range,
                                  alternate sentencing, or indeterminate
                                  sentencing with an outer range of greater than
                                  one year.
                                </span></span
                              >
                            </li>
                            <li>
                              Any misdemeanor involving:
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span><strong>
                                   CRL-2</strong>
                                All sexual crimes, criminal offenses of a sexual
                                  nature to include but not limited to; rape,
                                  child molestation, sexual battery, lewd
                                  conduct, possession and distribution of child
                                  pornography, possession and distribution of
                                  obscene material, prostitution, indecent
                                  exposure, public indecency, and any sex
                                  offender registrant;
                                </span
                              ></span>
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong>
                                    CRL-3</strong> Any drug related offenses;
                                </span></span
                              >
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong> CRL-4 </strong> Harm to a minor,
                                  including, but not limited to, offenses such
                                  as child abandonment, child
                                  endangerment/neglect/abuse, contributing to
                                  the delinquency of a minor, and DUI with a
                                  minor;
                                </span></span
                              >
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong>CRL-5 </strong> Violence against a
                                  person (including crimes involving a deadly
                                  weapon and domestic violence);</span
                                ></span
                              >
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong> CRL-6 </strong> Stalking,
                                  harassment, blackmail, violation of a
                                  protection order, and/or threats;</span
                                ></span
                              >
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong>CRL-7 </strong> Destruction of
                                  property, including arson, vandalism, and
                                  criminal mischief; and</span
                                ></span
                              >
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  ><strong> CRL-8 </strong> Animal abuse,
                                  cruelty, or neglect.</span
                                ></span
                              >
                            </li>
                          </ul>
                          <p>
                            To begin your background screening application,
                            please click NEXT.
                          </p>
<!--                          <p>
                            Have you already started? Pick up where you left
                            off.
                          </p>
                          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn"> Resume Application</button>-->
                          <h4> Other Questions</h4>
                          <p>National Center for Safety Initiatives LLC. (NCSI) is the official background screening provider for USA Water Ski & Wake Sports. If you would like to learn more about NCSI, please visit their website at <a target="_blank" href="https://solutions.ncsisafe.com">https://solutions.ncsisafe.com</a>.</p>
                          <p>For questions about this application process, you may contact support@ncsisafe.com.</p>
                          <p>To begin your background screening application, please click NEXT.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div v-if="wiz_step == 3" class="merchandise-view">
                <div class="row">
                  <div class="col-md-12">
                        <div class="card donation">
                            <div class="card-header register-title">
                            <h3>Applicant Information</h3>
                            </div>
                            <div class="card-body">
                                <p v-if="subdomain=='waterski'">TO AVOID DELAYS IN THE RETURN OF YOUR BACKGROUND SCREENING RESULTS, PLEASE MAKE SURE YOU:</p>
                                <p v-if="subdomain=='shooting'">Please enter your full legal name as would appear on any official identification (i.e driving license or passport).</p>
                              <ul class="grassrootul backscreen-group" v-if="subdomain=='waterski'">
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>Enter your LEGAL name as it appears on your current valid government-issued ID.</span></span></li>
                                <li><span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>DO NOT use nicknames (ex. Rob for Robert, Sue for Susan).</span></span></li>
                                <li><span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>Double-check your entry for accuracy before continuing with your submission.</span></span></li>
                                </ul>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="firstName" class="form-label" v-if="subdomain=='shooting'">Legal First Name<sup class="text-danger">*</sup></label>
                                      <label for="firstName" class="form-label" v-else>First Name<sup class="text-danger">*</sup></label>
                                      <input type="text" class="form-control" v-model="form.first_name">
                                        <span class="text-sm text-danger">{{ errors.first_name}}</span>
                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label for="middleinitial" class="form-label">Middle Initial</label>
                                    <input type="text" class="form-control" v-model="form.middle_name">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label" v-if="subdomain=='shooting'">Legal Last Name<sup class="text-danger">*</sup></label>
                                      <label for="lastname" class="form-label" v-else>Last Name<sup class="text-danger">*</sup></label>
                                      <input type="text" class="form-control" v-model="form.last_name">
                                      <span class="text-sm text-danger">{{ errors.last_name}}</span>
                                    </div>
                                </div>
                              <div class="col-md-3">
                                <div class="form-group birthdate">
                                  <label for="lastname" class="form-label">DOB<sup class="text-danger">*</sup></label>
                                  <date-pick
                                      v-model="form.dob"
                                      :value="form.dob | formatDob"
                                      :pickTime="false"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      name="form.dob"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <span class="text-sm text-danger">{{ errors.dob}}</span>
                                </div>
                              </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                   <label for="email" class="form-label">Email<sup class="text-danger">*</sup></label>
                                    <input type="email" class="form-control" v-model="form.email">
                                      <span class="text-sm text-danger">{{ errors.email}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="subdomain=='waterski' || form.backgroundScreeningType==1">
                                     <div class="form-group">
                                    <label for="ssn" class="form-label">SSN<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.ssn" @keypress="onlyNumber">
                                       <span class="text-sm text-danger">{{ errors.ssn}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                     <div class="form-group">
                                    <!-- <label for="lastname" class="form-label">Phone<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.phone">
                                       <span class="text-sm text-danger">{{ errors.phone}}</span> -->
                                        <div class="phone-nu-wrapper">
                                        <label for="phone" class="form-label">Phone Number<sup class="text-danger">*</sup></label>
                                        <vue-tel-input v-model="form.phone" ref="phone" maxlength="20" defaultCountry="US"  @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                        <span class="text-sm text-danger">{{ errors.phone }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="subdomain=='waterski'">
                                     <div class="form-group">
                                     <p class="font-13">To avoid delays: if you have Puerto Rico address history, please provide your Mother's maiden name.</p>
                                    <input type="text" class="form-control" v-model="form.mother_maiden_name">
                                    </div>
                                </div>
                                <hr>
                                <div class="col-md-12">
                                    <h4> Address History</h4>
                                    <p v-if="subdomain=='waterski'">The application will accept only the two-letter state abbreviation in the ST field (ex. CO for Colorado).</p>

                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">Street<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.street1">
                                      <span class="text-sm text-danger">{{ errors.street1}}</span>
                                    </div>
                                </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="lastname" class="form-label">Country<sup class="text-danger">*</sup></label>
                                  <select class="form-control" id="country" v-model="form.country" name="form.country" @change="countryChange">
                                    <option value="">Select</option>
                                    <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                  </select>
                                  <input type="hidden" name="form.country_code" v-model="form.country_code" />
                                  <span class="text-sm text-danger">{{ errors.country}}</span>
                                </div>
                              </div>
                              <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">City<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.city">
                                      <span class="text-sm text-danger">{{ errors.city}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">State/Province<sup class="text-danger">*</sup></label>
                                    <select class="form-control" id="state" v-model="form.state" name="form.state_code" v-if="usaState==true">
                                    <option value="">Select</option>
                                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                    </select>
                                    <select class="form-control" id="state" v-model="form.state" name="form.state" v-else-if="form.country == 'Canada'">
                                    <option value="">Select</option>
                                    <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                                    </select>
                                      <input type="text" v-else class="form-control" v-model="form.state" name="form.state_code">

                                      <span class="text-sm text-danger">{{ errors.state}}</span>
                                    </div>
                                </div>
                      
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label" v-if="subdomain=='shooting'">Postal Code<sup class="text-danger">*</sup></label>
                                      <label for="lastname" class="form-label" v-else>Zip Code<sup class="text-danger">*</sup></label>
                                      <input type="text" class="form-control" v-model="form.zip"  v-if="form.country == 'Canada'"/>
                                      <input type="text" class="form-control" v-model="form.zip" @keypress ="onlyNumber" v-else />
                                      <span class="text-sm text-danger">{{ errors.zip}}</span>
                                    </div>
                                </div>      

                            </div>
                            </div>
                        </div>
                    </div>
                   </div>
              </div>
              <div v-if="wiz_step == 9 && subdomain == 'shooting'  && form.backgroundScreeningType==2" class="merchandise-view">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card donation">
                      <div class="card-header register-title">
                        <h3>Additional Information</h3>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">International screening Country<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="additionalCountry" v-model="form.additionalCountry" name="form.additionalCountry" @change="additionalChange">
                                <option value="">Select</option>
                                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                              </select>
                              <input type="hidden" name="form.additionalCountry" v-model="form.additionalCountryCode" />
                              <span class="text-sm text-danger">{{ errors.additionalCountry}}</span>
                            </div>
                          </div>

                          <div class="col-md-4" v-if="usaAdditionalState==true">
                            <div class="form-group">
                              <label for="lastname" class="form-label">Nationality<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="state" v-model="form.additionalState" name="form.additionalState" >
                                <option value="">Select</option>
                                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.additionalState}}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-else>
                            <div class="form-group">
                              <label for="lastname" class="form-label">Nationality<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" id="state" v-model="form.additionalState" name="form.additionalState">
                              <span class="text-sm text-danger">{{ errors.additionalState}}</span>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="Province" class="form-label"> Province<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.Province">
                              <span class="text-sm text-danger">{{ errors.Province}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="social_security_number" class="form-label">Social Security Number<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.socialSecurityNumber" @keypress="onlyNumber">
                              <span class="text-sm text-danger">{{ errors.socialSecurityNumber}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="govtId" class="form-label">Government ID<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="" name="govtId" v-model="form.govtId">
                              <option value="1" selected>Passport</option>
                              <option value="2">Drivers Licence</option>
                              <option value="3">Military Id</option>
                              <option value="4">Other</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.govtId}}</span>
                              </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="govtIdNum" class="form-label">Government ID Number<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.govtIdNum">
                              <span class="text-sm text-danger">{{ errors.govtIdNum}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="motherName" class="form-label">Mothers Maiden Name<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.motherName">
                              <span class="text-sm text-danger">{{ errors.motherName}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="fatherName" class="form-label">Fathers Full Name<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.fatherName">
                              <span class="text-sm text-danger">{{ errors.fatherName}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="yearUs" class="form-label">Number Of Years In US<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.yearUs">
                              <span class="text-sm text-danger">{{ errors.yearUs}}</span>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 10 && ((form.backgroundScreeningType==2 && subdomain == 'shooting') || (form.lived_status!=1 && subdomain == 'waterski'))" class="merchandise-view">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card donation">
                      <div class="card-header register-title">
                        <h3>International Address</h3>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">Address Line 1<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.interAddrLine1">
                              <span class="text-sm text-danger">{{ errors.interAddrLine1}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">Address Line 2</label>
                              <input type="text" class="form-control" v-model="form.interAddrLine2">
                              <span class="text-sm text-danger">{{ errors.interAddrLine2}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">Country<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="interCountry" v-model="form.internatCountry" name="form.internationalCountry" @change="interCountryChange">
                                <option value="">Select</option>
                                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                              </select>
                              <input type="hidden" name="form.internatCountry" v-model="form.internatCountryCode" />
                              <span class="text-sm text-danger">{{ errors.internatCountry}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">City<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.interCity">
                              <span class="text-sm text-danger">{{ errors.interCity}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">State/Province<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="interState" v-model="form.interState" name="form.state_code" v-if="usaInterState==true" >
                                <option value="">Select</option>
                                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                              </select>
                              <select class="form-control" id="interState" v-model="form.interState" name="form.state_code" v-else-if="form.internatCountry=='Canada'" >
                                <option value="">Select</option>
                                <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                              </select>
                              <input type="text" v-else class="form-control" v-model="form.interState" name="form.state_code">

                              <span class="text-sm text-danger">{{ errors.interState}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="lastname" class="form-label">Postal Code<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" v-model="form.interZip" v-if="form.internatCountry=='Canada'"/>
                              <input type="text" class="form-control" v-model="form.interZip" @keypress ="onlyNumber" v-else/>
                              <span class="text-sm text-danger">{{ errors.interZip}}</span>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 4 && subdomain == 'shooting'">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-body card">
                      <div class="card-header register-title">
                        <h3>Disclosure</h3>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <p>
                            USA Shooting (the “Company”) and its affiliates, the United States Olympic and Paralympic Committee (USOPC), the U.S. Center for SafeSport (SafeSport) and a Responsible Sports Organization (RSO) (“Partners”) may obtain information about you from a third party consumer reporting agency for participation or employment purposes with: (a) an entity affiliated with the USOPC, (b) access to an Olympic Training Center, (c) participation in a Delegation Event, or (d) affiliated with SafeSport or with a Responsible Sports Organization, as defined by the USOPC Policy. Thus, you may be the subject of a “consumer report” which may include information about your character, general reputation, personal characteristics, and/or mode of living. These reports may contain information regarding your criminal history, social security verification, motor vehicle records (“driving records”), verification of your education or employment history, or other background checks.
                          </p>
                          <p v-if="subdomain=='waterski'">
                            The investigations will be conducted by National Center for Safety Initiatives, LLC (NCSI); 1853 Piedmont Road Suite 100, Marietta, GA 30066; tel. # 866-996-7412; www.solutions.ncsisafe.com.
                          </p>
                          <p v-else>
                            The investigations will be conducted by National Center for Safety Initiatives, LLC (NCSI); 39008 P.O. Box Cleveland, OH 44139; tel. # 866-996-7412;<a href="https://solutions.ncsisafe.com/">www.ncsisafe.com</a>.</p>
                          <p>
                            I acknowledge receipt of the DISCLOSURE REGARDING BACKGROUND INVESTIGATION and certify that I have read and understand the above statement.
                          </p>
                          <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[10].name">
                          <span class="text-sm text-danger">{{ errors.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-body card">
                      <div class="card-header register-title">
                        <h3>State and City Notices</h3>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mt-3">
                          <label for="lastname" class="form-label">I certify that I am an individual seeking prospective
                            or continued participation in:<sup class="text-danger">*</sup></label>
                          <select id="select-team" v-model="form.state_specific_work" class="form-control">
                            <option value="">Select</option>
                            <option v-for="(hstate, index) in listOfStates" :value="hstate.state_code"
                                    :key="'types'+index">{{ hstate.state }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.state_specific_work }}</span>
                          <div class="col-md-12 mt-3" v-if="form.state_specific_work == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you an individual seeking prospective or continued employment to work in San
                              Francisco, California?</label>
                            <select class="form-control" v-model="form.seeking_prospective_first">
                              <option value="" >Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.seeking_prospective_first }}</span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-3" v-if="form.state_specific_work == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you an individual seeking prospective or continued employment to work in Los
                              Angeles, California?</label>
                            <select class="form-control" v-model="form.seeking_prospective_second">
                              <option value="" >Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.seeking_prospective_second }}</span>
                          </div>
                        </div>
                        </div>                        
                        <div class="col-md-6 mt-3">
                          <label for="lastname" class="form-label">I am a resident of:<sup
                              class="text-danger">*</sup></label>
                          <select id="select-team" v-model="form.state_specific_home" class="form-control">
                            <option value="">Select</option>
                            <option v-for="(lstate, index) in listOfStates" :value="lstate.state_code"
                                    :key="'types-home'+index">{{ lstate.state }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.state_specific_home }}</span>
                          <div class="col-md-12 mt-3" v-if="form.state_specific_home == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you a resident of San
                              Francisco, California?</label>
                            <select class="form-control" v-model="form.resident_first">
                              <option value="">Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.resident_first }}</span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-3" v-if="form.state_specific_home == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you a resident of Los Angeles, California?</label>
                            <select class="form-control" v-model="form.resident_second">
                              <option value="">Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.resident_second }}</span>
                          </div>
                        </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              <div v-if="wiz_step == 5 && subdomain == 'shooting'" class="merchandise-view">
                <div class="col-md-12">
                  <div class="card donation">
                    <div class="card-header register-title">
                      <h3>Criminal History Information</h3>
                    </div>
                    <div class="card-body pb-5">
                      <b class="mb-3">Instructions for Completing Voluntary Disclosures</b>
                      <p class="mt-2">The Background Screening Policy for your organization requires review of any reportable records or disclosures, other than an adjudication of not guilty, or the existence of pending charges, for any of the criminal offenses listed below. DO NOT report offenses for which the record has been sealed or expunged by court order.</p>
                      <p class="font-13">*Please answer all of the following questions regarding your criminal history.</p>
                      <p><strong>Note: An answer of yes to any of the following questions will require details concerning where and when the incident occured and may result in your disqualification.</strong></p>
                      <div class="form-group">
                        <label for="question-1" class="form-label"><strong>Any felony-level offense?</strong><sup class="text-danger">*</sup></label>
                        <br> <input type="radio" id="q1-1" name="question-1"  value="1" v-model="form.questions1" @change="criminalStatusChange">
                        <label for="q1-1">Yes</label>
                        <input class="ml-2" type="radio" id="q1-2" name="question-1" value="2" v-model="form.questions1" @change="criminalStatusChange">
                        <label for="q1-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions1 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-2" class="form-label"><strong>Any misdemeanor-level offenses involving all sexual crimes, criminal offenses of a sexual nature including but not limited to; rape, child molestation, sexual battery, lewd conduct, possession and distribution of child pornography, possession and distrbution of obscene material, prostitution, indecent exposure, public indecency, and any sex offender registrant?</strong><sup  class="text-danger">*</sup></label>
                        <br><input type="radio" id="q2-1" name="question-2" value="1" v-model="form.questions2" @change="criminalStatusChange">
                        <label for="q2-1">Yes</label>
                        <input class="ml-2" type="radio" id="q2-2" name="question-2" value="2" v-model="form.questions2" @change="criminalStatusChange">
                        <label for="q2-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions2 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-3" class="form-label"><strong>Any misdemeanor-level drug related offenses?</strong><sup class="text-danger">*</sup></label>
                        <br><input type="radio" id="q3-1" name="question-3" value="1" v-model="form.questions3" @change="criminalStatusChange">
                        <label for="q3-1">Yes</label>
                        <input class="ml-2" type="radio" id="q3-2" name="question-3" value="2" v-model="form.questions3" @change="criminalStatusChange">
                        <label for="q3-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions3 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-4" class="form-label"><strong>Any misdemeanor-level offenses involving harm to a minor or vulnerable person, including, but not limited to, offenses such as child abandonment, child endangerment/neglect/abuse, contributing to the delinquency of a minor, and DUI with a minor?</strong><sup class="text-danger">*</sup></label>
                        <br><input type="radio" id="q4-1" name="question-4" value="1" v-model="form.questions4" @change="criminalStatusChange">
                        <label for="q4-1">Yes</label>
                        <input class="ml-2" type="radio" id="q4-2" name="question-4" value="2" v-model="form.questions4" @change="criminalStatusChange">
                        <label for="q4-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions4 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-5" class="form-label"><strong>Any misdemeanor-level offenses involving violence against a person, or threat of force (including crimes involving firearms and domestic violence)?</strong><sup class="text-danger">*</sup></label>
                        <br> <input type="radio"  id="q5-1" name="question-5" value="1" v-model="form.questions5" @change="criminalStatusChange">
                        <label for="q5-1">Yes</label>
                        <input class="ml-2" type="radio" id="q5-2" name="question-5" value="2" v-model="form.questions5" @change="criminalStatusChange">
                        <label for="q5-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions5 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-6" class="form-label"><strong>Any misdemeanor-level offenses involving stalking, harassment, blackmail, violation of a protection order and/or threats?</strong><sup class="text-danger">*</sup></label>
                        <br><input type="radio" id="q6-1" name="question-6" value="1" v-model="form.questions6" @change="criminalStatusChange">
                        <label for="q6-1">Yes</label>
                        <input class="ml-2" type="radio" id="q6-2" name="question-6" value="2" v-model="form.questions6" @change="criminalStatusChange">
                        <label for="q6-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions6 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-7" class="form-label"><strong>Any misdemeanor-level offenses involving destruction of property, including arson, vandalism, and criminal mischief?</strong><sup class="text-danger">*</sup></label>
                        <br><input type="radio" id="q7-1" name="question-7" value="1" v-model="form.questions7" @change="criminalStatusChange">
                        <label for="q7-1">Yes</label>
                        <input class="ml-2" type="radio"  id="q7-2" name="question-7" value="2" v-model="form.questions7" @change="criminalStatusChange">
                        <label for="q7-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions7 }}</span>
                      </div>
                      <div class="form-group">
                        <label for="question-8" class="form-label"><strong>Any misdemeanor-level offenses involving animal abuse, cruelty, or neglect?</strong><sup class="text-danger">*</sup></label>
                        <br><input type="radio"  id="q8-1" name="question-8" value="1" v-model="form.questions8" @change="criminalStatusChange">
                        <label for="q8-1">Yes</label>
                        <input class="ml-2" type="radio" id="q8-2" name="question-8" value="2" v-model="form.questions8" @change="criminalStatusChange">
                        <label for="q8-2">No</label>
                        <span class="text-sm text-danger">{{ errors.questions8 }}</span>
                      </div>
                    </div>
                    <div class="row" v-if="criminalStatus">
                      <div class="col-md-12">Please provide details of where and when the incident occurred. The following information is required as you have answered yes to one or more of the questions above.</div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">Country<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="criminalCountry" v-model="form.criminalCountry" name="form.criminalCountry" @change="criminalCountryChange">
                              <option value="">Select</option>
                              <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.criminalCountry}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">City<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" v-model="form.criminalCity">
                            <span class="text-sm text-danger">{{ errors.criminalCity}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">State/Province<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="state" v-model="form.criminalState" name="form.criminalState" v-if="usaCriminalState==true">
                              <option value="">Select</option>
                              <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                            <input type="text" class="form-control" id="state" v-model="form.criminalState" v-else name="form.criminalState">
                            <span class="text-sm text-danger">{{ errors.criminalState}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group birthdate">
                            <label for="lastname" class="form-label">Offense Date<sup class="text-danger">*</sup></label>
                            <date-pick
                                v-model="form.criminalOffDate"
                                :value="form.criminalOffDate | formatDob"
                                :pickTime="false"
                                :selectableYearRange="{from: 1900, to: 2021}"
                                name="form.dob"
                                :format="'MM/DD/YYYY'"
                                v-mask="'##/##/####'"
                                :isDateDisabled="isFutureDate"
                                placeholder="MM/DD/YYYY"
                                :parseDate="parseDatePick"
                            ></date-pick>
                            <span class="text-sm text-danger">{{ errors.criminalOffDate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">Court<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" v-model="form.criminalCourt">
                            <span class="text-sm text-danger">{{ errors.criminalCourt}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">Court Type<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" v-model="form.criminalCourtType">
                            <span class="text-sm text-danger">{{ errors.criminalCourtType}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group">
                          <label for="lastname" class="form-label">Charge Level<sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" v-model="form.criminalChargeLevel">
                          <span class="text-sm text-danger">{{ errors.criminalChargeLevel}}</span>
                        </div>
                      </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">Which of the following best describes this charge? (Please note, if your case has been sealed, expunged, or subsequently dismissed, you will need to provide paper work to NCSI to show this outcome)<sup class="text-danger">*</sup></label>
                          <select class="form-control" id="type" name="criminalType" v-model="form.criminalType">
                            <option value="0">select on Option</option>
                            <option value="1">I have had it sealed</option>
                            <option value="2">I have had it expunged</option>
                            <option value="3">I was subsequently dismissed</option>
                            <option value="4">I have not taken any steps to have it removed from my record</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.criminalType}}</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          Please provide details of the final charge and final disposition. <span class="text-danger"> Failure to provide the actual convicted charge (eg battery, possession, robbery etc.) may result in delays in processing your application. </span></div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="form-label">Details<sup class="text-danger">*</sup></label>
                            <textarea class="form-control" v-model="form.criminalDetails" placeholder="Enter details"></textarea>
                            <span class="text-sm text-danger">{{ errors.criminalDetails}}</span>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

              </div>
              <div v-if="wiz_step == 6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <div class="card-header register-title d-none">
                        <h3 v-if="form.state_specific_home == 'CA'"> SIGNATURE ({{ waiver_index > 4?waiver_index-1: waiver_index+1 }} OF {{ 8 }})</h3>
                        <h3 v-else-if="form.state_specific_home == 'CA'"> SIGNATURE ({{ waiver_index > 4?waiver_index-1: waiver_index+1 }} OF {{ 8 }})</h3>
                        <h3 v-else-if="form.state_specific_home == 'NY'"> SIGNATURE ({{ waiver_index > 5?5: waiver_index+1 }} OF {{ 7 }})</h3>
                        <h3 v-else> SIGNATURE ({{ waiver_index > 4?4: waiver_index+1 }} OF {{ 7 }})</h3>
                      </div>
                     <div v-show="waiver_index == 0" class="card card-body">
                      <div class="register-title"><h3>ELECTRONIC SIGNATURE CONSENT</h3></div> 
                       <p>As part of the selection process at USA <span v-if="subdomain=='waterski'">Water Ski & Wake Sports</span><span v-if="subdomain=='shooting'">Shooting</span>, the "Company," you will need to consent to a background check electronically. By typing your name, you are consenting to receive any communications (legally required or otherwise) and all changes to such communications electronically. In order to use the website, you must provide at your own expense an Internet connected device that is compatible with the minimum requirements outlined below. You also confirm that your device will meet these specifications and requirements and will permit you to access and retain the communications electronically each time you access and use the website.</p>
                    <p><strong>System Requirements to Access Information</strong></p>
                     To receive and view an electronic copy of the Communications you must have the following equipment and software:
                     <ul class="listitems-ul">
                       <li>
                         A personal computer or other device which is capable of accessing the Internet. Your access to this page verifies that your system/device meets these requirements.
                       </li>
                       <li>
                         A current version of Chrome, Firefox, Safari, Internet Explorer, or Microsoft Edge Internet web browser which supports security industry best practices for HTTPS encrypted communications, JavaScript, and cookies. Your access to this page verifies that your browser meets these requirements.
                       </li>
                     </ul>
                     <p><strong>System Requirements to Retain Information</strong></p>
                     <p>To retain a copy, you must either have a printer connected to your personal computer or other device or, alternatively, the ability to save a copy through use of printing service or software such as Adobe Acrobat®.</p>
                     <p><strong>Withdrawal of Electronic Acceptance of Disclosures and Notices</strong></p>

                       <p>You can also contact <span v-if="subdomain=='shooting'">us</span><span v-else>NCSI</span> to withdraw your consent to receive any future communications electronically, including if the system requirements described above change and you no longer possess the required system. If you withdraw your consent, we will terminate your use of the National Center for Safety Initiatives, LLC (NCSI) website and the services provided through the National Center for Safety Initiatives, LLC (NCSI) website.</p>
                     <p v-if="subdomain=='waterski'">To ensure that a signature is unique and to safeguard you against unauthorized use of your name, your IP address {{ ipAddressVal }} has been recorded and will be stored along with your electronic signature. Please note that if you wish to submit your Disclosure and Authorization Forms electronically, National Center for Safety Initiatives, LLC (NCSI) requires that you include your social security number or user identification. All of your information will be encrypted and transmitted via our secure website.</p>
                     
                     <div class="card p-4">
                       <p>By typing my name below, I consent to transacting electronically, including receiving legally required notices electronically. I understand that <span v-if="subdomain=='shooting'">NCSI </span>uses computer technology to ensure that my signed documents are not altered after submission. I agree to allow <span v-if="subdomain=='shooting'">NCSI </span>to validate my signed documents in this way.</p>
                       <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                       <span class="text-sm text-danger">{{ errors.name}}</span>
                     </div>
                     </div>
                     <div v-show="waiver_index == 1" class="card card-body">
                       <div class="register-title"><h3>Disclosure Regarding Background Investigation</h3></div>
                        <p>{{ getSiteProps('general.site_name') }} (the “Company”) may obtain information about you from a third party consumer
                        reporting agency for employment purposes. Thus, you may be the subject of a “consumer report”
                        which may include information about your character, general reputation, personal characteristics,
                        and/or mode of living. These reports may contain information regarding your criminal history, social security verification, motor vehicle records (“driving records”), verification
                        of your education or employment history, or other background checks.</p>
                       <p>The investigations will be conducted by <b>National Center for Safety Initiatives, LLC (NCSI); P.O. Box 39008 Cleveland, Ohio 44139; tel. # 866-996-7412;</b> <a href="www.solutions.ncsisafe.com" target="_blank">www.solutions.ncsisafe.com.</a></p>
                       <div class="background-gray card p-4">
                         <p>By typing my name below, I acknowledge receipt of the DISCLOSURE REGARDING BACKGROUND INVESTIGATION and certify that I have read and understand this document.</p>
                         <div class="row">
                           <div class="col-md-10">
                             <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                             <span class="text-sm text-danger">{{ errors.name}}</span>
                           </div>
                           <div class="col-md-2">
                             <p>Date: {{ currentDate }}</p>
                           </div>
                         </div>
                       </div>
                       </div>
                      <div v-show="waiver_index == 2" class="card card-body">
                                               
                      <img src="\dist\img\profile\backimg\FCRA\0001.jpg">
                      <img src="\dist\img\profile\backimg\FCRA\0002.jpg">
                      <img src="\dist\img\profile\backimg\FCRA\0003.jpg">
                      <img src="\dist\img\profile\backimg\FCRA\0004-2024.jpg">
                      <div class="card p-4">
                        By typing my name below, I acknowledge receipt of the Summary of Your Rights Under the Fair Credit Reporting Act (FCRA) and certify that I have read and understand this document.
                        <input type="text" class="form-control"  placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                          <span class="text-sm text-danger">{{ errors.name}}</span>
                      </div>

                      </div>
                      <div v-show="waiver_index == 3" class="card card-body">
                        <img src="/dist/img/profile/backimg/NY-Article-23A.jpg" v-if="form.state_specific_home =='NY' || form.state_specific_work == 'NY'"/>
                        <template v-else>
                          <div class="register-title"><h3>Other State Law Notices</h3></div>
                          <table border="1" class="other_state_rules">
                            <tbody>
                            <tr>
                              <td>
                                <b>New York residents and applicants only:</b> Upon request, you will be informed whether or
                                not a consumer report was requested by the Company, and if such report was requested,
                                informed of the name and address of the consumer reporting agency that furnished the
                                report. You have the right to inspect and receive a copy of any investigative consumer
                                report requested by the Company by contacting the consumer reporting agency directly.
                                By signing the Acknowledgement and Authorization for Background Investigation,
                                you acknowledge receipt of Article 23-A of the New York Correction Law.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>New York City residents and applicants only:</b> By signing the Acknowledgement and
                                Authorization for Background Investigation, you further authorize the Company to
                                provide you with a copy of your consumer report, the New York City Fair Chance Act
                                Notice form, and any other documents, to the extent required by law, at the mailing
                                address and/or email address you provide to the Company.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Minnesota residents and applicants only:</b> You have the right to submit a written request
                                to the consumer reporting agency for a complete and accurate disclosure of the nature
                                and scope of any consumer report the Company ordered about you. The consumer reporting
                                agency must provide you with this disclosure within five business days after its receipt
                                of your request or the report was requested by the Company, whichever date is later.
                                Please check this box if you would like to receive a copy of a consumer report if one is
                                obtained by the Company. <input type="checkbox" id="state_rule_minnesota_check" value="1" @change="waiverAdditionalDataChange">
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Oklahoma residents and  applicants only:</b> Please check this box if you would like to
                                receive a copy of a consumer report if one is obtained by the Company.
                                <input type="checkbox" id="state_rule_oklahoma_check" value="1" @change="waiverAdditionalDataChange">
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Los Angeles residents and applicants only:</b> Please <a href="https://bca.lacity.org/Uploads/fciho/Ban%20the%20Box%20Poster%20-%20Private%20Employers%2C%20as%20of%208.23.18.pdf" target="_blank">click here</a>
                                to receive a copy of the <u>Notice to Applicants & Employees Fair Chance Initiative for
                                Hiring Ordinance.</u>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>San Francisco residents and applicants only:</b> Please <a href="https://sfgov.org/olse/sites/default/files/FCO%20poster2020.pdf" target="_blank">click here</a> to receive a copy of the
                                <u>San Francisco Fair Chance Ordinance Notice.</u>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Washington State residents and applicants only:</b> You have the right to request from the
                                consumer reporting agency a written summary of your rights and remedies under the
                                Washington Fair Credit Reporting Act.
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </template>

                        <template v-if="form.state_specific_home =='NY' || form.state_specific_work == 'NY'">
                          <h1>NEW YORK CITY APPLICANTS ONLY</h1>
                          <p>By clicking the box below, you further authorize {{ getSiteProps('general.site_name') }} ("the Company") to provide you with a copy of your consumer report, the New York City Fair Chance Act Notice form, and any other documents, to the extent required by law, at the mailing address and/or email address you provide to the Company.</p>
                        </template>
                        <div class="background-gray">
                          <div class="p-3">
                            <label for="iunderstand2" class="form-check-label"
                            ><input
                                type="checkbox"
                                id="iunderstand2"
                                class="form-check-input" v-model="waiverSign[waiver_index].agree_first"
                            />
                              <template v-if="form.state_specific_home =='NY' || form.state_specific_work == 'NY'">
                                I acknowledge receipt of the NEW YORK CORRECTION LAW ARTICLE 23-A, certify that I have read and understand these documents.
                              </template>
                              <template v-else>
                                I acknowledge receipt of the Other States Notice, certify that I have read and understand these documents.
                              </template>
                            </label>
                            <span class="text-sm text-danger"><br>{{ errors.agree_first}}</span>
                          </div>
                        </div>
                      </div>
                      <div v-show="waiver_index == 4 && (form.state_specific_home =='CA' || form.state_specific_work == 'CA')" class="card card-body">
                      <img src="/dist/img/profile/backimg/0001.jpg">                      
                       <img src="/dist/img/profile/backimg/0002.jpg" class="p-2">
                       <div class="card p-5">
                              <label for="iunderstand" class="form-check-label"
                        ><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" /> I acknowledge receipt of the San Francisco Fair Chance Ordinance and certify that I have read and understand this document.
                        </label>
                          <span class="text-sm text-danger">{{ errors.agree_first}}</span>
                       </div>                   
                      </div>
                      <div v-show="waiver_index == 5 && (form.state_specific_home =='CA' || form.state_specific_work == 'CA')" class="card card-body">
                       <img src="/dist/img/profile/backimg/los-1.jpg">                      
                       <img src="/dist/img/profile/backimg/los-2.jpg">
                        <div class="card p-5">
                        <label for="iunderstand" class="form-check-label"
                        ><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" /> I acknowledge receipt of the City of Los Angeles and certify that I have read and understand this document.</label>
                          <span class="text-sm text-danger">{{ errors.agree_first}}</span>
                        </div>
                      </div>
                      <div v-show="waiver_index == 6 && (form.state_specific_home =='CA' || form.state_specific_work == 'CA')" class="card card-body">
                        <img src="/dist/img/profile/backimg/USAWSWS-disclosure-CA.png" v-if="subdomain === 'waterski'">
                        <img src="/dist/img/profile/backimg/USAS-disclosure-CA.png" v-if="subdomain === 'shooting'">
                        <div class="card p-5">
                        <label for="iunderstand" class="form-check-label"><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" />Please check this box if you would like to receive a copy of an investigative consumer report or consumer credit report at no charge if one is obtained by the Company whenever you have a right to receive such a copy under California law.</label>
                        <span class="text-sm text-danger">{{ errors.agree_first}}</span>
                        <label for="iunderstand" class="form-check-label"><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_second" />I acknowledge receipt of the NOTICE REGARDING BACKGROUND CHECKS PER CALIFORNIA LAW and certify that I have read and understand this document.</label>
                        <span class="text-sm text-danger">{{ errors.agree_second}}</span>
                      </div>                        
                      </div>
                      <div v-show="waiver_index == 7" class="card card-body">
                        <div class="register-title"><h3>ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND INVESTIGATION</h3></div>                        
                        <p>I acknowledge receipt of the separate documents entitled DISCLOSURE REGARDING BACKGROUND INVESTIGATION, DISCLOSURE FOR INVESTIGATIVE CONSUMER REPORT (if applicable), A SUMMARY OF YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and OTHER STATE LAW NOTICES and certify that I have read and understand those documents. I hereby authorize the obtaining of “consumer reports” and/or “investigative consumer reports” by <b>{{ getSiteProps("general.site_name") }}</b> (the “Company“) at any time after receipt of this authorization and throughout my employment, if applicable. To this end, I hereby authorize, without reservation, any law enforcement agency, administrator, state or federal agency, institution, school or university (public or private), information service bureau, employer, or insurance company to furnish any and all background information requested by <b>National Center for Safety Initiatives, LLC (NCSI); P.O. Box 39008 Cleveland, Ohio 44139; tel. # 866-996-7412;</b> <a href="www.solutions.ncsisafe.com">www.solutions.ncsisafe.com</a> and/or the Company. I agree that a facsimile (“fax”), electronic or photographic copy of this Authorization shall be as valid as the original.</p>
                        <div class="pl-3 pt-3 pr-3 pb-3">
                          <label for="iunderstand" class="form-check-label"><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" />Please check this box to receive from NCSI; a copy of any report furnished by NCSI to the Company pursuant to your authorization.</label>
                          <br><span class="text-sm text-danger">{{ errors.agree_first}}</span>
                        </div>                        
                        <div class="background-gray gray">
                        <div class="row">
                        <div class="col-md-4">
                        <label>Name<sup class="text-danger" v-if="subdomain=='shooting'">*</sup></label> 
                        <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                          <span class="text-sm text-danger">{{ errors.name}}</span>
                        </div>
                        <div class="col-md-4">
                        <label>ID/SSN (last 4)<sup class="text-danger" v-if="subdomain=='shooting'">*</sup></label> 
                        <input type="text" class="form-control" placeholder="Last 4 Digit" v-model="waiverSign[waiver_index].ssn" @keypress="onlyNumber">
                          <span class="text-sm text-danger">{{ errors.ssn}}</span>
                        </div>
                        <div class="col-md-4 mt-4" v-if="subdomain=='waterski'">
                        <label>Ip Address:</label> 
                        {{ ipAddressVal }}
                        </div>
                        </div>
                        </div>
                      </div>
                      <!--no proper flow -->
                      <div v-show="waiver_index == 8" class="card card-body">
                        <div class="register-title"><h3>Additional Screening Consent</h3></div>
                        <p>
                          I acknowledge receipt of the separate document entitled DISCLOSURE REGARDING
                          BACKGROUND SEARCH and A SUMMARY OF YOUR RIGHTS UNDER THE FAIR
                          CREDIT REPORTING ACT and certify that I have read and understand both of those
                          documents. I hereby authorize an additional background search within 24 months of
                          my initial search authorization by the obtaining of &quot;consumer reports&quot; and/or
                          &quot;investigative consumer reports&quot; by {{ getSiteProps("general.site_name") }} (&quot;Company&quot;) at any time after
                          receipt of this authorization so long as employment or participation continues as coach,
                          volunteer, staff, board member, official or other authorized representative position
                          (Position) within the Company, if applicable. To this end, I hereby authorize, without
                          reservation, any law enforcement agency, administrator, state, municipal or federal
                          agency, institution, school or university (public or private), information service bureau,
                          employer, or insurance company to furnish any and all background information and
                          applicable records requested by <b>National Center for Safety Initiatives LLC (&quot;NCSI&quot;),
                          P.O. Box 39008, Cleveland, OH 44139; tel. # 866-996-7412; <a href="www.ncsisafe.com">www.ncsisafe.com</a> and
                          their agents,</b> and/or Company. I agree that a facsimile (&quot;fax&quot;), electronic or
                          photographic copy of this Authorization shall be as valid as the original. I understand
                          that I will have an opportunity to review the records as received by {{ getSiteProps("general.site_name") }} and a
                          procedure is available if I dispute the records that have been received.
                        </p>
                        <div class="row">
                          <div class="col-md-12">
                            <label>Name<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                            <span class="text-sm text-danger">{{ errors.name  }}</span>
                          </div>
                        </div>
                     </div>
                    <div v-show="waiver_index == 9" class="card card-body">
                      <img src="\dist\img\profile\backimg\disclosure-inv-consumer-USAWS.png" alt="" v-if="subdomain === 'waterski'">
                      <img src="\dist\img\profile\backimg\disclosure-inv-consumer-USAS.png" alt="" v-if="subdomain === 'shooting'">
                      <div class="background-gray card p-4">
                        <p>By typing my name below, I acknowledge receipt of the DISCLOSURE REGARDING
                          “INVESTIGATIVE CONSUMER REPORT” and certify that I have read and understand this document.</p>
                        <div class="row">
                          <div class="col-md-10">
                            <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                            <span class="text-sm text-danger">{{ errors.name}}</span>
                          </div>
                          <div class="col-md-2">
                            <p>Date: {{ currentDate }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 7">
                <div class="row payment-view">
                  <b-overlay :show="showLoader" no-wrap></b-overlay>
                  <div class="col-md-6 mb-view-pr mb-view-pl">
                    <div class="card-body card">
                      <div class="row">
                        <div class="col-md-12">
                          <h3>Total Amount</h3>
                        </div>
                        <div class="col-md-12">
                          <ul class="list-group mb-3 payment-details">
                            <li class="d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>{{ form.type }}</strong></h6>
                              </div>
                              <span class="text-muted"><strong>${{ fees.fee }}</strong></span>
                            </li>
                            <div>
                            <li class=" d-flex justify-content-between  ">
                                                            <div class="coupon-inner mb-3">
                                                                <h6 class="my-0"><strong>Coupon</strong></h6>
                                                            </div>
                                                            <div class="">
                                                                <input type="text" class="form-control coupon-text" v-model="couponApplied.coupon_code"><button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{ button.coupon }}</button>
                                                                <span class="text-sm text-danger">{{ couponErrors.error}}</span>
                                                                <span class="text-sm text-success">{{ couponErrors.success}}</span>
                                                            </div>
                                                        </li>
                                                     
                                                       
                                                        <li v-if="couponApplied.status == 1" class="d-flex justify-content-between lh-sm">
                                                        <div>
                                                        <h6 class="my-0"><strong><a href="javascript:void(0);" class="text-danger" @click="removeCoupon()"><i class="fas fa-trash-alt"></i></a> Discount - {{ couponApplied.text }} </strong></h6>
                                                        </div>
                                                        <span class="text-muted"><strong>-${{ couponApplied.amount }}</strong></span>
                                                        </li>
                                                        <li class="d-flex justify-content-between lh-sm">
                                                            <div>
                                                                <h6 class="my-0"><strong>Sub Total</strong></h6>
                                                            </div>
                                                            <span class="text-muted"><strong>${{ totals.sub_total }}</strong></span>
                                                        </li>
                                                        <li class=" d-flex justify-content-between lh-sm" v-if="subdomain=='waterski'">
                                                          <div class="processing-tooltip">
                                                            <h6 class="my-0"><strong>Technology/Processing Fee ({{ fees.processingFeePercent }}%)
                                                              <b-button v-b-tooltip.hover
                                                                        title="This small fee enables USA-WSWS to make technology upgrades to enhance your membership experience.">
                                                                <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                                                     alt="tooltipicon"/></b-button>
                                                            </strong></h6>
                                                          </div>
                                                          <span class="text-danger"><strong>${{ fees.processingFee }}</strong></span>
                                                        </li>
                                                   </div>
                            <li class=" d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>Grand Total</strong></h6>
                              </div>
                              <span class="text-danger"><strong>${{ fees.grand_total }}</strong></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-view-pl mb-view-pr">
                    <div class="card-body card card-wrapper" v-if="paymentForm.visible">
                      <div class="row">
                        <div class="col-md-12 card-info">
                          <h3>Card Information</h3>
                          <div class="text-right">
                            <!--                                                                            <img v-bind:src="'/dist/img/memberregistration/stripe.png'" alt="stripe">-->
                          </div>
                        </div>
                        <template v-if="getSiteProps('general.paymentGateway') == 'stripe'">
                          <div class="col-md-12">
                            <div class="form-group mb-0">
                              <label class="form-label">Card Number / Expiry Month and Year / CVC<sup class="text-danger">*</sup></label>
                              <stripe-element-card
                                  v-show="wiz_step == 7"
                                  ref="stripeForm"
                                  :pk="stripeKey"
                                  :hidePostalCode="true"
                                  iconStyle="solid"
                                  @token="stripeTokenCreated"
                                  @error="captureStripeErrors"
                                  @element-change="stripeFormChanges"
                              />
                              <!-- <span class="text-sm text-danger" v-if="stripeError">{{ stripeError }}</span> -->
                            </div>
                            <div class="form-group">
                              <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                              <input type="text" placeholder="William" class="form-control" id="billing_firstname"
                                     name="paymentForm.billing_firstname" v-model="paymentForm.billing_firstname">
                              <span class="text-sm text-danger">{{ errors.billing_firstname }}</span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                            <input type="hidden" name="paymentForm.amount" v-model="paymentForm.amount">
                            <input type="hidden" name="paymentForm.registration_id" v-model="paymentForm.registration_id">
                            <input type="text" maxlength="16" placeholder="#### #### #### ####" class="form-control"
                                   name="paymentForm.cardNumber" v-model="paymentForm.cardNumber"
                                   @keypress="isNumber($event)">
                            <!--                                                                                <input class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" v-cardformat:formatCardNumber>-->
                            <span class="text-sm text-danger">{{ errors.cardNumber }}</span>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                          <select class="form-control form-select" id="expiration-month" required=""
                                  name="paymentForm.expiration_month" v-model="paymentForm.expiration_month">
                            <option value="">Month</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.expiration_month }}</span>
                        </div>
                        <div class="col-md-3 mp-l-r pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
                          <select class="form-select form-control" id="expiration-year" required=""
                                  name="paymentForm.expiration_year" v-model="paymentForm.expiration_year">
                            <option value="">Year</option>
                            <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.expiration_year }}</span>
                        </div>
                        <div class="col-md-3 classcvv">
                          <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                          <input type="password" class="form-control" name="paymentForm.cvv" maxlength="4"
                                 v-model="paymentForm.cvv" @keypress="isNumber($event)">
                          <span class="text-sm text-danger">{{ errors.cvv }}</span>
                        </div>
                          <div class="form-group">
                            <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                            <input type="text" placeholder="William" class="form-control" id="billing_firstname"
                                   name="paymentForm.billing_firstname" v-model="paymentForm.billing_firstname">
                            <span class="text-sm text-danger">{{ errors.billing_firstname }}</span>
                          </div>
                        </template>
                        <hr/>
                        <div class="col-md-12">
                          <h3>Billing Address</h3>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Mailing Address<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_addressline1"
                                   v-model="paymentForm.billing_addressline1">
                            <span class="text-sm text-danger">{{ errors.billing_addressline1 }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>City<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_city"
                                   v-model="paymentForm.billing_city">
                            <span class="text-sm text-danger">{{ errors.billing_city }}</span>
                          </div>
                        </div>

                        <div class="col-md-6 pr-0" v-if="paymentForm.billing_country == 'USA'">
                          <div class="form-group">
                            <label>State<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="state_code" name="paymentForm.billing_state_code"
                                    v-model="paymentForm.billing_state_code">
                              <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                      :value="listOfState.state_code"
                                      :selected="paymentForm.billing_state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.billing_state_code }}</span>
                          </div>
                        </div>
                        <div class="col-md-6 pr-0" v-else-if="paymentForm.billing_country == 'Canada'">
                          <div class="form-group">
                            <label>State<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="state_code" name="paymentForm.billing_state"
                                    v-model="paymentForm.billing_state">
                              <option v-for="listOfState in listOfCAStates" :key="listOfState.state_code"
                                      :value="listOfState.state"
                                      :selected="paymentForm.billing_state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label for="state_code" class="form-label">State <sup
                                class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" v-model="paymentForm.billing_state"
                                   name="paymentForm.billing_state">
                            <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                          </div>
                        </div>
                        <div class="col-md-6 pr-0">
                          <div class="form-group">
                            <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="15" name="paymentForm.billing_zip"
                                   v-model="paymentForm.billing_zip" v-if="paymentForm.billing_country == 'Canada'">
                            <input type="text" class="form-control" maxlength="15" name="paymentForm.billing_zip"
                                   v-model="paymentForm.billing_zip" @keypress="onlyNumber" v-else>
                            <span class="text-sm text-danger">{{ errors.billing_zip }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Country<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="country" v-model="paymentForm.billing_country"
                                    name="paymentForm.billing_country">
                              <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                      :value="listOfCountry.country"
                                      :selected="paymentForm.billing_country == listOfCountry.country">
                                {{ listOfCountry.country }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.billing_country }}</span>
                          </div>
                        </div>
                        <div class="col-md-12" v-if="this.getSiteProps('general.payment_fee_info')">
                          <!-- <p class="mt-3 font-13" style="font-size:11px;margin-bottom:0;"><i>All transactions also include a 3% processing/technology fee.</i></p> -->
                        </div>
                        <hr/>
                        <div class="col-md-12 payment-conf confirmsc">
                          <span class="text-sm text-danger">{{ errors.payment_agree }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 8" class=" verify-view">
                <div class="col-md-12">
                  <div class="card-body card pt-5 pb-5">
                    <h3 class="text-center">Thank You!</h3>
                    <p class="text-center">Your Application for {{ form.type }} is Submitted!</p>
                    <div class="col-md-4">

                    </div>
                    <div class="offset-4 col-md-4 text-center mb-4">
                      <div class="">
                        <button type="button" @click.prevent="viewApplication()" class="btn btn-outline-primary cancelbtn btn-previious">Check Application</button>
                      </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
<!--                    <p class="text-center">Please  log out when you are finished to protect youbtn btn-primary savr Information</p>
                    <div class="offset-4 col-md-4 text-center">
                      <div class="">
                    <button type="button" class="btn btn-outline-primary cancelbtn btn-previious" disabled>Logout</button>
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
              <div class="col-md-12 pr-0 text-right mt-2 ">
                <div class="btn-group pb-3" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    v-if="wiz_step != 1 && wiz_step != registerTotalstep && (wiz_step != 7 || subdomain=='waterski')"
                    @click.prevent="wizardPrevstep"
                    class="btn btn-outline-primary cancelbtn btn-previious  "
                  >
                    Previous Step
                  </button>
                  <button
                    type="button"
                    v-if="wiz_step != registerTotalstep && this.internationalAddressNext==true"
                    @click.prevent="wizardNextstep"
                    class="btn btn-secondary btnnextstp btndisabled btn-next-success"
                  >
                    {{ button.nextStepButton }}
                  </button>
                  <button
                      type="button"
                      v-if="wiz_step != registerTotalstep && this.internationalAddressNext==false"
                      @click.prevent=""
                      class="btn btn-secondary btnnextstp btndisabled btn-next-success" style="opacity: 0.6"
                  >
                    {{ button.nextStepButton }}
                  </button>
                  <b-modal ref="my-modal" hide-footer>
                    <div class="d-block text-center">
                      <h3>Your details are save?</h3>
                    </div>
                    <b-button class="mt-3" variant="outline-danger" block @click="toggleModal">Ok</b-button>
                    <b-button class="mt-2" variant="outline-warning" block @click="hideModal">Cancel</b-button>
                  </b-modal>
                  <b-modal ref="internation-modal" hide-footer>
                    <div class="d-block text-center">
                      You are not eligible to submit International Background Screening.                    </div>
                    <b-button class="mt-3" variant="outline-danger" block @click="hideInternationalModal">Ok</b-button>
                  </b-modal>
                </div>
              </div>
            </div>
            <div v-else-if="backgroundScreen != null || internationalBackgroundScreen!=null">
              <section>
                <div class="profile-body card user-details" v-if="backgroundScreen !=null && (subdomain != 'shooting' || backgroundScreen.background_screening_type_id != 20)">
                  <div class="card-header register-title">
                    <h3 v-if="subdomain=='waterski'">Application Status</h3>
                    <h3 v-if="subdomain=='shooting'">Background Screening</h3>
                    <button type="button" @click.prevent="viewSecondBackground()" class="btn btn-secondary btnnextstp btndisabled btn-next-success" v-if="subdomain==='shooting' && prices && (prices.background_screening || prices.international_background_screening)">Add Background Screening</button>
                    <h5 class="text-danger text-center mt-3" v-else-if="subdomain==='shooting'">Background Screening purchase is disabled now. Come again later</h5>
                    <b-button v-if="subdomain=='waterski'" @click.prevent="applyNew(event);" class="pull-right">Purchase Background Screening</b-button>
                  </div>
                  <div class="card-body">
                    <div class="row" v-if="props_fields && props_fields.personal">
                      <div  class="col-md-6 col-lg-4" v-if="props_fields.personal.title">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.title.label || 'Title'}}</label>
                          <span >{{ profileData.info.title || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4 ">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.firstname.label || 'First Name'}}</label>
                          <span >{{ backgroundScreen.ncsi.first_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.middlename.label || 'Middle Initial'}}</label>
                          <span >{{ backgroundScreen.ncsi.middle_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label" >{{props_fields.personal.lastname.label || 'Last Name'}}</label>
                          <span >{{ backgroundScreen.ncsi.last_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.birthday.label || 'Birthday'}}</label>
                          <span >{{ formatlongDate(backgroundScreen.ncsi.dob) || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.email.label || 'Email'}}</label>
                          <span >{{ backgroundScreen.ncsi.email || "N/A" }}</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='waterski'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{'Mother\'s maiden name'}}</label>
                          <span >{{ backgroundScreen.ncsi.mother_maiden_name || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.mobilephone.label || 'Country Code with Mobile'}}</label>
                          <span >{{ backgroundScreen.ncsi.phone || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.address1.label || 'Mailing Address'}}</label>
                          <span >{{ backgroundScreen.ncsi.street1 || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.country.label || 'Country'}}</label>
                          <span >{{ backgroundScreen.ncsi.country || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.city.label || 'City'}}</label>
                          <span >{{ backgroundScreen.ncsi.city || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.state_code.label || 'State'}}</label>
                          <span >{{ backgroundScreen.ncsi.state || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields.contact.zip.label || 'Postal Code' }}</label>
                          <span>{{ backgroundScreen.ncsi.zip || "N/A" }}</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='shooting'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Background Screening Type' }}</label>
                          <span>Background Screening</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='waterski'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Lived Status' }}</label>
                          <span>{{ backgroundScreen.ncsi.lived_status_text || "N/A" }}</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='waterski'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Resident of' }}</label>
                          <span>{{ backgroundScreen.ncsi.state_specific_home || "N/A" }}</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='waterski'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Employment to work in' }}</label>
                          <span>{{ backgroundScreen.ncsi.state_specific_work || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Amount (USD)</label>
                          <span>{{ (profileData.info.background_screening.payment != null)?profileData.info.background_screening.payment.amount:"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Id</label>
                          <span>{{ ((profileData.info.background_screening.payment != null)?profileData.info.background_screening.payment.transaction_code:"N/A") || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Date</label>
                          <span>{{ (profileData.info.background_screening.payment != null)?usdate(profileData.info.background_screening.payment.transaction_time) :"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="profileData.info.background_screening">
                        <div class="form-group">
                          <label class="form-label">Background Screen Status <span v-if="profileData.info.background_screening.background_screening_info" v-b-tooltip.hover :title="profileData.info.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                          <span class="text-success" v-if="profileData.info.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.background_screening.background_screening_status_text || "N/A"}}</span>
                          <span class="text-warning" v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.background_screening.background_screening_status_text || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-4" v-else>
                        <div class="form-group">
                          <label class="form-label">Background Screen Status</label>
                          <span class="text-warning"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> N/A</span>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="internationalBackgroundScreen == null">
                        <div class="form-group">
                          <label class="form-label">Waivers and Notices</label>
                          <button class="btn btn-success ml-1 mt-0" @click="downloadDocs(profileData.info.background_screening.id)">
                            <i class="fas" :class="{ 'fa-circle-notch fa-spin' : waiverDownloading, 'fa-download': !waiverDownloading }"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="profile-body card user-details" v-if="internationalBackgroundScreen!=null">
                  <div class="card-header register-title">
                    <h3 v-if="subdomain=='waterski'">Application Status</h3>
                    <h3 v-if="subdomain=='shooting'">International Background Screening</h3>
                    <button type="button" @click.prevent="viewSecondBackground()" class="btn btn-secondary btnnextstp btndisabled btn-next-success" v-if="backgroundScreen == null && subdomain=='shooting'">Add Background Screening</button>
                    <b-button v-if="subdomain=='waterski'" @click.prevent="applyNew(event);" class="pull-right">Purchase Background Screening</b-button>
                  </div>
                  <div class="card-body">
                    <div class="row" v-if="props_fields && props_fields.personal">
                      <div  class="col-md-6 col-lg-4" v-if="props_fields.personal.title">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.title.label || 'Title'}}</label>
                          <span >{{ profileData.info.title || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4 ">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.firstname.label || 'First Name'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.first_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.middlename.label || 'Middle Initial'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.middle_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label" >{{props_fields.personal.lastname.label || 'Last Name'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.last_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.birthday.label || 'Birthday'}}</label>
                          <span >{{ formatlongDate(internationalBackgroundScreen.ncsi.dob) || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.email.label || 'Email'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.email || "N/A" }}</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='waterski'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{'Mother\'s maiden name'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.mother_maiden_name || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.mobilephone.label || 'Country Code with Mobile'}}</label>
                          <span >{{ profileData.info.internationalBackgroundScreening.ncsi.phone || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.address1.label || 'Mailing Address'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.street1 || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.country.label || 'Country'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.country || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.city.label || 'City'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.city || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.state_code.label || 'State'}}</label>
                          <span >{{ internationalBackgroundScreen.ncsi.state || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields.contact.zip.label || 'Postal Code' }}</label>
                          <span>{{ internationalBackgroundScreen.ncsi.zip || "N/A" }}</span>
                        </div>
                      </div>
                      <div v-if="subdomain=='shooting'" class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Background Screening Type' }}</label>
                          <span>International Background Screening</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Amount (USD)</label>
                          <span>{{ (internationalBackgroundScreen.payment != null)?internationalBackgroundScreen.payment.amount:"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Id</label>
                          <span>{{ ((internationalBackgroundScreen.payment != null)?internationalBackgroundScreen.payment.transaction_code:"N/A") || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Date</label>
                          <span>{{ (internationalBackgroundScreen.payment != null)?usdate(internationalBackgroundScreen.payment.transaction_time) :"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="internationalBackgroundScreen">
                        <div class="form-group">
                          <label class="form-label">Background Screen Status <span v-if="internationalBackgroundScreen.background_screening_info" v-b-tooltip.hover :title="internationalBackgroundScreen.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                          <span class="text-success" v-if="internationalBackgroundScreen.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ internationalBackgroundScreen.background_screening_status_text || "N/A"}}</span>
                          <span class="text-warning" v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{internationalBackgroundScreen.background_screening_status_text || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-4" v-else>
                        <div class="form-group">
                          <label class="form-label">Background Screen Status</label>
                          <span class="text-warning"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> N/A</span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="form-label">Waivers and Notices</label>
                          <button class="btn btn-success ml-1 mt-0" @click="downloadDocs(profileData.info.background_screening.id)">
                            <i class="fas" :class="{ 'fa-circle-notch fa-spin' : waiverDownloading, 'fa-download': !waiverDownloading }"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div v-else-if="memberAge < 18">
              <section>
                <div class="card donation">
                  <div class="card-header register-title">
                    <h3>Validation</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="text-center">You must be 18+ to do Background Screening</p>
                      </div>
                    </div>
                    </div>
                  </div>
              </section>
            </div>
            <div v-else>
              <section>
                <div class="card donation">
                  <div class="card-header register-title">
                    <h3>Validation</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="text-center">No Background Screening Required</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from "axios";
import Vue from "vue";
import {StripeElementCard} from "@vue-stripe/vue-stripe";

Vue.filter('formatDob', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});
const validateName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (name ==  undefined || !name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "This field");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}

const validateDob = (dob, fieldDob = '') => {
  let fieldDobText = (fieldDob != '' ? fieldDob : "This field");
  if (!dob.length) {
    return { valid: false, error: fieldDobText+" is required" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
const validateGender = (gender,fieldGender = '') => {
  let genderText = ( fieldGender != '' ? fieldGender : "This field");
  if (!gender) {
    return { valid: false, error: genderText+" is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = select => {
  if (select == "" || select == null) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = (email,mail,subdomain) => {
  if (!email.length) {
    return { valid: false, error:"Email is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};
const validateWaiverInitials = name => {
  if (!name) {
    return { valid: false, error: "Please enter your Initials to proceed" };
  } else {
    if(name.length != 2) {
      return { valid: false, error: "You must enter two letters to initial" };
    }
  }
  return { valid: true, error: null };
};
const validateWaiverCheckbox = checkbox => {
  if (!checkbox) {
    return { valid: false, error: "Please acknowledge by checking the box" };
  }
  return { valid: true, error: null };
};
const validateStateName = name => {
  if (name == null || name == "") {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateCardNumber = cardNumber => {
  if (!cardNumber.length || cardNumber == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((cardNumber.replace(/\s/g,'')).length < 14) {
    return { valid: false, error: "Card number must be greater than 13 digits" };
  }
  return { valid: true, error: null };
};
const validateCvv = cvv => {
  if (!cvv.length || cvv == "") {
    return { valid: false, error: "This field is required" };
  }
  if (!cvv.match(/^[0-9]{3,4}$/)) {
    return { valid: false, error: "CVV must be 3 or 4 digits"};
  }
  return { valid: true, error: null };
};
const validateSsnNumber = ssnNumber => {
  if (!ssnNumber.length || ssnNumber.trim() == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((ssnNumber.replace(/\s/g,'')).length != 9) {
    return { valid: false, error: "Please enter valid SSN" };
  }
  return { valid: true, error: null };
};
const validateQuestion = question => {
  if (question == null || question == "") {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateFirstName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateZip = (zipCode) => {
  if(!zipCode.match(/^\d{5}(-\d{4})?$/) || !zipCode.length ){
    return { valid: false, error: "Postal Code Must Be Atleast 5 Character" };
  }
  return { valid: true, error: null };
};
const validateCanadaZip = (zipCode) => {
  if(zipCode.length < 3 || !zipCode.length ){
    return { valid: false, error: "Postal Code Invalid" };
  }
  return { valid: true, error: null };
};
const validateSsnWaiverNumber = (ssnNumber,oldssn1,oldssn2) => {
  if (!ssnNumber.length || ssnNumber == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((ssnNumber.replace(/\s/g,'')).length < 3) {
    return { valid: false, error: "Please enter last 4 digits of your SSN" };
  }
  if (ssnNumber==oldssn1 || ssnNumber==oldssn2 && this.subdomains=='shooting'){
    return { valid: false, error: "Please enter fill same SSN" };
  }
  return { valid: true, error: null };
};
const validateDobnew = (fielddob,dob,domain) => {
  if (!fielddob.length) {
    return { valid: false, error: "Birth date is required" };
  }
  else if(fielddob!=dob && domain=='shooting'){
    return { valid: false, error: "Date of birth is not matching your profile" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
export default {
  name: "backroundscreeningform",
  data() {
    return {
      bgScreenEnable: false,
      waiverDownloading: false,
      ipAddressVal: '',
      loading: true,
      waiver_index: 0,
      total_waiver: 3,
      wiz_step: 1,
      waiverSign: [],
      waiverTypeData: [],
      registerTotalstep: 8,
      backgroundType:true,
      criminalStatus:false,
      form: {
        lived_status: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        email: '',
        dob: '',
        ssn: '',
        mother_maiden_name: '',
        address_type: 1,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        country_code: 'US',
        state_specific_work: '',
        state_specific_home: '',
        signature_name: '',
        type: 'Background Screening',
        fee: 0.00,
        processingFee: 0.00,
        grand_total: 0.00,
        processingFeePercent: 3,
        seeking_prospective_first: '',
        seeking_prospective_second: '',
        resident_first: '',
        resident_second: '',
        background_screening_type_id: 19,
        questions1: '',
        questions2: '',
        questions3: '',
        questions4: '',
        questions5: '',
        questions6: '',
        questions7: '',
        questions8: '',
        criminalCountry: '',
        criminalCity: '',
        criminalState: '',
        criminalOffDate: '',
        criminalCourt: '',
        criminalCourtType: '',
        criminalChargeLevel: '',
        criminalType: 0,
        criminalDetails: '',
        backgroundScreeningType: 1,
        additionalCountry : '',
        additionalCountryCode : '',
        additionalState:'',
        govtId:1,
        internationalAddr:1,
        interAddrLine1: '',
        interAddrLine2: '',
        internatCountry: '',
        internatCountryCode: '',
        interCity: '',
        interState: '',
        interZip:'',
        socialSecurityNumber:'',
        ca_receive_icr: '',
        waiverSign: []
      },
      type: 'Background Screening',
      country: 'USA',
      fees: {
        fee: 0.00,
        processingFee: 0.00,
        grand_total: 0.00,
        processingFeePercent: 3,
        internationalFee: {
          1: 0.00,
          2: 0.00,
          3: 0.00
        }
      },
      valid: false,
      errors: {},
      currentDate: moment().format('MM/DD/YYYY hh:mm'),
      paymentForm: {
        stripeToken: "",
        cardNumber: '',
        expiration_year: '',
        expiration_month: '',
        cvv: '',
        amount: 30.90,
        owner: '',
        name_on_card: "",
        registration_id: '',
        billing_firstname: '',
        billing_lastname: '',
        billing_company: 'USA Waterski',
        billing_addressline1: '',
        billing_zip: '',
        billing_state: '',
        billing_state_code: '',
        billing_city: '',
        billing_country: '',
        billing_phone1: '',
        auto_renew: 1,
        background_screening_type_id: 19,
        visible: true,
        coupon_id:'',
        discount:''
      },
      prop_footer: {},
      prop_confirmation: {
        agree: "I agree to the USA-WSWS <a href='/privacy-policy' target='_blank'>Terms & Conditions policy</a>",
        label: "By submitting this information, you agree to the <a href='/privacy-policy' target='_blank'>Privacy Policy</a> and <a href='/terms-and-conditions' target='_blank'>Terms & Conditions</a> policy,<span class='auto_renew_text_sign'>including AUTO-RENEWAL and</span> understand that there are NO REFUNDS.",
        note: "Please check the box and add your initials in the space provided.",
      },
      button: {
        nextButton: 'Next',
        nextStepButton: 'Next Step',
        coupon: 'Apply Coupon'
      },
      bgsId: '',
      waiverData: [],
      signedWaiverData: [],
      memberAge: '',
      backgroundScreen: null,
      props_fields: null,
      profileData: {
        info:[],
        SafeSport: {
          ActiveStatus: ''
        },
        BGScreening: {
          BackgroundReportPackage: {
            ScreeningStatus: {
              OrderStatus: ''
            }
          }
        },
      },
      validTelinput:false,
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:20,
          name:"telephone",
          placeholder:"Mobile Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }
      },
      secondBackGround: '',
      usaState:true,
      usaCriminalState:true,
      usaAdditionalState:true,
      stripeToken: null,
      stripeError: "",
      stripeValidate: false,
      internationalAddress:false,
      usaInterState:true,
      internationalAddressNext:true,
      internationalBackgroundScreen: null,
      couponErrors: {},
      couponApplied: {
          status: 0,
          coupon_code: '',
          amount: 0.00,
          text: ''
      },
      totals: {
          sub_total: 0.00,
          grand_total: 0.00,
      },
      prices: {}
    };
  },
  components: {
    StripeElementCard
  },
  methods: {
    wizardNextstep: function () {
      if(this.wiz_step == 1) {
        if(!this.validateType() & this.subdomain == 'waterski') {
          return;
        }
        if(this.subdomain == 'shooting'){
          if(this.backgroundType==false){
            this.fees.fee=parseFloat(this.prices?.international_background_screening || 0).toFixed(2);
            this.form.background_screening_type_id=20;
            this.paymentForm.background_screening_type_id=20;
            this.form.type='International Background Screening';
          }else{
            this.fees.fee=parseFloat(this.prices?.background_screening || 0).toFixed(2);
            this.form.background_screening_type_id=19;
            this.paymentForm.background_screening_type_id=19;
            this.form.type='Background Screening';
          }
          this.feeCalculate();
          this.wiz_step = 3;
          return;
        }
      } else if(this.wiz_step == 3) {
        if(!this.validate()) {
          return;
        } else if(this.subdomain == 'waterski' & this.form.lived_status != 1 )  {
          this.wiz_step = 10;
          return;
        }else if(this.subdomain == 'waterski' & this.form.lived_status == 1 )  {
          this.wiz_step = 5;
          return;
        } else if(this.subdomain == 'shooting' & this.form.backgroundScreeningType==2) {
          this.wiz_step = 9;
          return;
        } else if(this.subdomain == "shooting") {
          this.waiver_index = 9;
        }
      }else if(this.subdomain == 'shooting' & this.wiz_step == 9){
        if(this.validAdditional()==false) {
          return;
        }
        if(this.form.backgroundScreeningType==2) {
          this.wiz_step = 10;
        }else {
          this.wiz_step = 4;
        }
        return;
      }else if(this.wiz_step == 10){
        if(this.validInter()==false) {
          return;
        }
        this.wiz_step = 5;
        if(this.subdomain == 'shooting') {
          this.wiz_step = 4;
          this.waiver_index = 9;
        }
        return;
      }else if(this.wiz_step == 4 & this.subdomain == 'shooting') {
         if(this.validateWaiverForm() == false & this.subdomain == 'shooting') {
           return;
         }
        this.wiz_step = 5;
         this.waiver_index = 0;
        return;
      }else if (this.wiz_step == 5) {
        if (!this.validateStates()) {
          return;
        }
        if (!this.validCriminal() && this.subdomain == 'shooting') {
          return;
        }
        if(this.form.state_specific_work == 'CA' ) {
          this.total_waiver = 8;
        } else if(this.form.state_specific_work == 'NY') {
          this.total_waiver = 4;
        }
      } else if (this.wiz_step == 6) {
        if (!this.validateWaiverForm()) {
          return;
        }
          if(this.waiver_index == this.total_waiver) {
            this.applyBackGroundScreen();
            return;
          } else {
            if(this.waiver_index == 2) {
              this.waiver_index = 9;
              this.total_waiver = 4;
              if(this.form.state_specific_home == 'CA' || this.form.state_specific_work == 'CA'){
                this.total_waiver = 8;
              }
            } else if(this.waiver_index == 3) {
              this.waiver_index = 7;
              if(this.form.state_specific_home == 'CA' || this.form.state_specific_work == 'CA'){
                this.total_waiver = 8;
              } else {
                this.total_waiver = 7;
              }
            }else if(this.waiver_index == 7 && this.total_waiver == 4) {
              this.waiver_index = 7;
              if(this.form.state_specific_home == 'CA' || this.form.state_specific_work == 'CA'){
                this.total_waiver = 8;
              } else {
                this.total_waiver = 7;
              }
            } else if(this.waiver_index == 2 && this.total_waiver == 7 && this.form.state_specific_work != 'NY' && this.form.state_specific_work != 'CA') {
              this.waiver_index = 7;
              this.total_waiver = 7;
            }else if(this.waiver_index == 2 && this.total_waiver == 7) {
              this.waiver_index = 4;
            } else if(this.waiver_index == 6) {
              this.waiver_index = 3;
            } else if(this.waiver_index == 9) {
              if(this.total_waiver == 8){
                this.waiver_index = 4;
              } else {
                this.waiver_index = 3;
              }
            } else {
              this.waiver_index++;
            }
            return;
          }

      } else if (this.wiz_step == 7) {
        if (this.paymentForm.amount == 0.0) {
          this.paymentApi();
          return;
        } else {

          if (this.validatePayment()) {
            if (this.subdomain == 'waterski') {
              this.paymentApi();
              return;
            } else {

              console.log("regstep=" + this.wiz_step);
              console.log('payment valid');

              console.log("Stripe Form:", this.$refs.stripeForm);
              if (this.$refs.stripeForm != undefined) {
                console.log(1234);
                //Stripe Token generation
                this.showLoader = true;
                this.$refs.stripeForm.submit();
              } else {
                this.paymentApi();
              }
              return;
            }
          } else {
            console.log('payment un valid');
            document.getElementById('app').scrollIntoView();
            return;
          }
        }
      }

      this.wiz_step++;
    },
    wizardPrevstep: function () {
      if(this.waiver_index == 8 || this.waiver_index == 0) {
        if(this.wiz_step == 3 & this.subdomain == 'shooting') {
          this.wiz_step = 1;
        }else if(this.wiz_step == 5 & this.subdomain == 'waterski') {
          this.wiz_step = 10;
        }else if(this.wiz_step == 5 & this.subdomain == 'shooting') {
          this.wiz_step = 4;
        }else if(this.wiz_step == 4 & this.subdomain == 'shooting' & this.form.backgroundScreeningType==2) {
          this.wiz_step = 10;
        } else if(this.wiz_step == 9 & this.subdomain == 'shooting') {
          this.wiz_step = 3 ;
        } else if(this.wiz_step == 10 & this.subdomain == 'waterski') {
          this.wiz_step = 3;
        } else {
          this.wiz_step--;
        }
        if(this.form.state_specific_work != 'CA' && this.form.state_specific_work != 'NY') {
          this.total_waiver = 7;
        } else if(this.form.state_specific_work == 'NY') {
          this.total_waiver = 7;
        }
      }
      if(this.waiver_index == 7) {
        if(this.form.state_specific_work != 'CA' && this.form.state_specific_work != 'NY') {
          this.waiver_index = 3;
          this.total_waiver = 4;
          return;
        } else if(this.form.state_specific_work == 'NY') {
          this.waiver_index = 3;
          this.total_waiver = 4;
          return;
        }
      }
      if(this.waiver_index == 8 && this.subdomain=='shooting') {
        this.waiver_index = 0;
        return;
      }
      if(this.waiver_index == 4 && (this.form.state_specific_work == 'CA' || this.form.state_specific_home == 'CA')) {
        this.waiver_index = 2;
        return;
      }
      if(this.waiver_index == 9) {
        this.waiver_index = 2;
        return;
      }
      if(this.waiver_index == 3) {
        if((this.form.state_specific_work == 'CA' || this.form.state_specific_home == 'CA')){
          this.waiver_index = 6;
          return;
        }
        this.waiver_index = 9;
        return;
      }
      if(this.waiver_index != 0) {
        this.waiver_index--;
      }
    },
    livedStatus(status) {
      this.form.lived_status = status;
      if(status === 2) {
        this.fees.fee = parseFloat(this.prices.background_screening_canada).toFixed(2);
      } else if(status === 3) {
        this.fees.fee = parseFloat(this.prices.international_background_screening).toFixed(2);
      } else {
        this.fees.fee = parseFloat(this.prices.background_screening).toFixed(2);
      }
      if(status != 1) {
        this.form.background_screening_type_id = 20;
        this.paymentForm.background_screening_type_id = 20;
        this.form.type = 'International Background Screening';
      }else{
        this.form.background_screening_type_id = 19;
        this.paymentForm.background_screening_type_id = 19;
        this.form.type = 'Background Screening';
      }
      this.feeCalculate();
    },
    loadMembersInfo() {
      axios
          .get(this.basePath + "api/member/my_profile", {
            headers: this.memberHeaders,
          })
          .then((response) => {
            let profileData = response.data.data;
            this.profileData.info = profileData;
            this.bgScreenEnable = profileData?.bgScreeningEnable ?? false;
            var props = Object.keys(this.form);
            if(props.length) {
              this.memberAge = this.getAge(profileData.dob);
              this.backgroundScreen = profileData.background_screening;
              this.internationalBackgroundScreen = profileData.internationalBackgroundScreening;
              if(profileData.groupCommittee != null)
                this.groupCommittee = profileData.groupCommittee;
            }

            if(this.backgroundScreen != null && this.backgroundScreen.status == 5) {
              this.wiz_step = 7;
              console.log(this.wiz_step);
              this.bgsId = this.backgroundScreen.ncsi.id;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    applyBackGroundScreen() {
      this.button.nextStepButton = 'Submitting...';
      // $.each(this.waiverSign, function(key, info){
      //
      // });
      this.waiverSign.forEach((value, index) => {
        this.waiverSign[index].type = this.waiverTypeData[index].type;
        this.waiverSign[index].ncsi_document_id = this.waiverTypeData[index].ncsi_document_id;
        this.waiverSign[index].slug = this.waiverTypeData[index].slug;
      });
      this.form.waiverSign = this.waiverSign;
      this.form.init_amount = this.fees.fee;
      axios.post(this.basePath + 'api/member/apply_back_ground_screen', this.form, {headers: this.memberHeaders})
          .then((res) => {
            if (res.data.status == 'success') {
              this.bgsId = res.data.data.id;
              if(!this.groupCommittee.payment) {
                this.wiz_step +=2;
              } else {
                this.wiz_step++;
              }
              this.waiver_index++;
              this.button.nextStepButton = 'Next Step';
              this.showLoader=false;
              return res;
            } else {
              this.button.nextStepButton = 'Next Step';
              this.showLoader=false;
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            this.button.nextStepButton = 'Next Step';
            this.showLoader=false;
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
    waiverApi() {
      axios.get(this.basePath + 'api/waivers/' + this.bgsId)
          .then((res) => {
              this.waiverData = res.data.data;
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    getAge(birth_date) {
      let birth_date_arr = birth_date.split('/');
      let today_date = new Date();
      let today_year = today_date.getFullYear();
      let today_month = today_date.getMonth();
      let today_day = today_date.getDate();
      let age = today_year - birth_date_arr[2];

      if (today_month < (birth_date_arr[0] - 1)) {
        age--;
      }
      if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1])) {
        age--;
      }
      return age;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    validateType() {
      this.errors = {}
      this.valid = false;
      if(this.form.lived_status == '') {
        this.errors.status = "Please choose living status";
      } else {
        this.errors.status = "";
        this.valid = true;
      }
      return this.valid;
    },
    validate() {
      this.errors = {}
      this.valid = false;
      const validFirstName = validateFirstName(this.form.first_name);
      this.errors.first_name = validFirstName.error;

      const validLastName = validateLastName(this.form.last_name);
      this.errors.last_name = validLastName.error;

      const validDob = validateDobnew(this.form.dob, this.profileData.info.dob,this.subdomain);
      this.errors.dob = validDob.error;

      const validSsn = validateSsnNumber(this.form.ssn, "SSN");
      this.errors.ssn = validSsn.error;

      const validEmail = validateEmail(this.form.email, this.profileData.info.email,this.subdomain);
      this.errors.email = validEmail.error;

      const validPhone = validatePhone(this.form.phone, this.validTelinput);
      this.errors.phone = validPhone.error;

      const validAddress = validateName(this.form.street1, "Street");
      this.errors.street1 = validAddress.error;

      const validState = validateSelect(this.form.state, "State");
      this.errors.state = validState.error;

      const validCountry = validateSelect(this.form.country, "Country");
      this.errors.country = validCountry.error;

      const validCity = validateName(this.form.city, "City");
      this.errors.city = validCity.error;

      const validZip = (this.form.country != 'USA')?validateCanadaZip(this.form.zip):validateZip(this.form.zip);
      this.errors.zip = validZip.error;
      
      
  

      if (validFirstName.valid && validLastName.valid  && validEmail.valid && validDob.valid && validPhone.valid && validAddress.valid && validCity.valid && validState.valid && validZip.valid && validCountry.valid) {
        if(this.form.backgroundScreeningType==2 || validSsn.valid !=false ){

        let dob = this.getAge(this.form.dob);

        if (!this.validateDate(this.form.dob)) {
          this.valid = false;
          this.errors.dob = 'Invalid Date';
        } else {
          this.errors.dob = '';
          this.valid = true;
          if (dob < 18) {
            this.valid = false;
            this.errors.dob = "You must be 18+ to do Background Screening";
            window.scrollTo(0, 0);
          }
        }
        }
      }

      return this.valid;
    },
    validateStates() {
      this.errors = {};
      this.valid = false;
      const validHome = validateSelect(this.form.state_specific_home, "State Specific Home");
      this.errors.state_specific_home = validHome.error;

      const validWork = validateSelect(this.form.state_specific_work, "State Specific Work");
      this.errors.state_specific_work = validWork.error;

      if(validHome.valid && validWork.valid) {
        this.valid = true;
        if(this.form.state_specific_work == 'CA') {
          const validSeekingFirst = validateSelect(this.form.seeking_prospective_first, "Seeking Prospective Work");
          const validSeekingSecond = validateSelect(this.form.seeking_prospective_second, "Seeking Prospective Work");
          if(!validSeekingFirst.valid || !validSeekingSecond.valid) {
            this.errors.seeking_prospective_first = validSeekingFirst.error;
            this.errors.seeking_prospective_second = validSeekingSecond.error;
            this.valid = false;
          }
        }
        if(this.form.state_specific_home == 'CA') {
          const validResidentFirst = validateSelect(this.form.resident_first, "Resident First");
          const validResidentSecond = validateSelect(this.form.resident_second, "Resident Second");
          if(!validResidentFirst.valid || !validResidentSecond.valid) {
            this.errors.resident_first = validResidentFirst.error;
            this.errors.resident_second = validResidentSecond.error;
            this.valid = false;
          }
        }
      }
      return this.valid;
    },
    validateWaiverForm() {
      this.errors = {};
      this.valid = false;
      let validName = validateName(this.waiverSign[this.waiver_index].name);
      this.errors.name = validName.error;

      const validAgreeFirst = validateWaiverCheckbox(this.waiverSign[this.waiver_index].agree_first);
      this.errors.agree_first = validAgreeFirst.error;

      const validAgreeSecond = validateWaiverCheckbox(this.waiverSign[this.waiver_index].agree_second);
      this.errors.agree_second = validAgreeSecond.error;

      const validSsnNumber = validateSsnWaiverNumber(this.waiverSign[this.waiver_index].ssn,this.form.ssn,this.form.socialSecurityNumber);
      this.errors.ssn = validSsnNumber.error;

      if(this.waiver_index == 0 || this.waiver_index == 1 || this.waiver_index == 2 || this.waiver_index == 9) {
        if(this.subdomain === "shooting" && this.wiz_step == 4 && this.waiver_index == 9){
          validName = validateName(this.waiverSign[10].name);
        }
        if(validName.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 3) {
        if(validAgreeFirst.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 4 || this.waiver_index == 5) {
        if(validAgreeFirst.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 6) {
        if(validAgreeSecond.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 7) {
        if(validName.valid && validAgreeFirst.valid && validSsnNumber.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 8) {
        if(validName.valid) {
          this.valid = true;
        }
      }
      return this.valid;
    },
    validatePayment() {
      this.errors = {}
      this.valid = false;

      const validPaymentFirstName = validateName(this.paymentForm.billing_firstname);
      this.errors.billing_firstname = validPaymentFirstName.error;
      let validCardNumber = { valid: true, error: null };
      let validExpirationYear = { valid: true, error: null };
      let validExpirationMonth = { valid: true, error: null };
      let validCvv = { valid: true, error: null };
if (this.subdomain=='waterski'){

      const validCardNumber = validateCardNumber(this.paymentForm.cardNumber);
      this.errors.cardNumber = validCardNumber.error;

      const validExpirationYear = validateSelect(this.paymentForm.expiration_year);
      this.errors.expiration_year = validExpirationYear.error;

      const validExpirationMonth = validateSelect(this.paymentForm.expiration_month);
      this.errors.expiration_month = validExpirationMonth.error;

      const validCvv = validateCvv(this.paymentForm.cvv);
      this.errors.cvv = validCvv.error;
}
      const validAddress1 = validateName(this.paymentForm.billing_addressline1);
      this.errors.billing_addressline1 = validAddress1.error;

      const validCountry = validateSelect(this.paymentForm.billing_country);
      this.errors.billing_country = validCountry.error;

      const validStateCodeSelect = validateSelect(this.paymentForm.billing_state_code);
      const validStateCodeInput = validateStateName(this.paymentForm.billing_state);

      if(validCountry.valid && this.paymentForm.billing_country == 'USA') {
        this.errors.billing_state_code = validStateCodeSelect.error;
      } else {
        this.errors.billing_state = validStateCodeInput.error;
      }

      const validCity = validateName(this.paymentForm.billing_city);
      this.errors.billing_city = validCity.error;

      const validZip = (this.paymentForm.billing_country != 'USA')?validateCanadaZip(this.paymentForm.billing_zip):validateZip(this.paymentForm.billing_zip);
      this.errors.billing_zip = validZip.error;

      if (validPaymentFirstName.valid && validCardNumber.valid
          && validExpirationYear.valid && validExpirationMonth.valid && validCvv.valid && validAddress1.valid && validCountry.valid
          && validCity.valid && validZip.valid && this.stripeError == "") {
        this.valid = true;
        if(this.paymentForm.billing_country == 'USA') {
          this.paymentForm.billing_state = '';
          if(!validStateCodeSelect.valid) {
            this.valid = false;
          }
        } else if(!validStateCodeInput.valid) {
          this.paymentForm.billing_state_code = '';
          this.valid = false;
        }
      }

      return this.valid;
    },
    feeCalculate() {
      if(this.subdomain == 'shooting'){
        this.fees.processingFeePercent = 0;
      }
      this.fees.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.fees.fee))/100 * this.removeCommaFromNumber(this.fees.processingFeePercent)).toFixed(2);
      this.fees.grand_total = parseFloat(this.removeCommaFromNumber(this.fees.fee)+this.removeCommaFromNumber(this.fees.processingFee)).toFixed(2);
      this.totals.sub_total = parseFloat(this.removeCommaFromNumber(this.fees.fee)).toFixed(2);
      this.paymentForm.amount = this.fees.grand_total;
      if(this.couponApplied.status == 1){
        this.calculateTotals();
      }
    },
    paymentApi() {
      this.showLoader=true;
      console.log("Payment submitted!");
      this.button.nextStepButton = 'Payment is in progress...';
      this.paymentForm.cardNumber = this.paymentForm.cardNumber.replace(/\s/g,'');
      this.paymentForm.name_on_card = this.paymentForm.billing_firstname;
      this.feeCalculate();
      console.log(this.paymentForm);
      this.paymentForm.init_amount = this.fees.fee;
      let url = (this.paymentForm.visible)?'api/background_screening_paynow/':'api/background_screening_zero_payment/';
      axios.post(this.basePath+url+this.bgsId, this.paymentForm)
          .then((res) => {
            if(res.data.status == 'success') {
              //Perform Success Action
              this.showLoader=false;
              this.button.nextStepButton = 'Next Step';
              this.paymentData = res.data.message;
              this.wiz_step++;
              return res;
            } else {
              this.showLoader=false;
              this.errors.payment_agree = res.data.errors;
              this.button.nextStepButton = 'Next Step';
            }
          })
          .catch((error) => {
            this.showLoader=false;
            console.log('Error:'+ error);
            this.errors.payment_agree = error.errors;
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    viewApplication() {
      this.loadMembersInfo();
      window.location.reload();
    },
    backgroundScreeningType(){
        if(this.form.backgroundScreeningType == 1){
          this.backgroundType=true;
        }else if(this.form.backgroundScreeningType == 2){
          this.backgroundType=false;
        }
      return false;
    },
    viewSecondBackground(){
      this.secondBackGround=true;
      this.loadMembersInfo();
    },
    criminalStatusChange(){
       if (this.form.questions1==1 || this.form.questions2=='1' || this.form.questions3=='1' || this.form.questions4=='1' || this.form.questions5=='1' || this.form.questions6=='1' || this.form.questions7=='1' || this.form.questions8=='1'){
       this.criminalStatus=true;
     }else {
       this.criminalStatus=false;
     }

      return false;
    },
    validCriminal(){
      this.errors = {}
        const validQuestion1 = validateQuestion(this.form.questions1, "question1");
        this.errors.questions1 = validQuestion1.error;

        const validQuestion2 = validateQuestion(this.form.questions2, "questions2");
        this.errors.questions2 = validQuestion2.error;

        const validQuestion3 = validateQuestion(this.form.questions3, "question3");
        this.errors.questions3 = validQuestion3.error;

        const validQuestion4 = validateQuestion(this.form.questions4, "questions4");
        this.errors.questions4 = validQuestion4.error;

        const validQuestion5 = validateQuestion(this.form.questions5, "question5");
        this.errors.questions5 = validQuestion5.error;

        const validQuestion6 = validateQuestion(this.form.questions6, "questions6");
        this.errors.questions6 = validQuestion6.error;

        const validQuestion7 = validateQuestion(this.form.questions6, "question7");
        this.errors.questions7 = validQuestion7.error;

        const validQuestion8 = validateQuestion(this.form.questions8, "questions8");
        this.errors.questions8 = validQuestion8.error;
      if (validQuestion1.valid && validQuestion2.valid && validQuestion3.valid && validQuestion4.valid && validQuestion5.valid && validQuestion6.valid && validQuestion7.valid && validQuestion8.valid) {
        if (this.criminalStatus == true) {
          const validCriminalCounrty = validateSelect(this.form.criminalCountry, "Country");
          this.errors.criminalCountry = validCriminalCounrty.error;

          const validCriminalCity = validateName(this.form.criminalCity, "City");
          this.errors.criminalCity = validCriminalCity.error;

          const validCriminalState = validateStateName(this.form.criminalState, "State");
          this.errors.criminalState = validCriminalState.error;

          const validCriminalOffDate = validateDob(this.form.criminalOffDate, "Offense Date");
          this.errors.criminalOffDate = validCriminalOffDate.error;

          const validCriminalCourt = validateName(this.form.criminalCourt, "Court");
          this.errors.criminalCourt = validCriminalCourt.error;

          const validCriminalCourtType = validateName(this.form.criminalCourtType, "Court Type");
          this.errors.criminalCourtType = validCriminalCourtType.error;

          const validCriminalType = validateSelect(this.form.criminalType, "Type");
          this.errors.criminalType = validCriminalType.error;

          const validCriminalDetails = validateName(this.form.criminalDetails, "Details");
          this.errors.criminalDetails = validCriminalDetails.error;

          const validCriminalChargeLevel = validateName(this.form.criminalChargeLevel, "Charge Level");
          this.errors.criminalChargeLevel = validCriminalChargeLevel.error;

          if (validCriminalCounrty.valid && validCriminalCity.valid && validCriminalState.valid && validCriminalOffDate.valid && validCriminalCourt.valid && validCriminalCourtType.valid && validCriminalType.valid && validCriminalDetails.valid && validCriminalChargeLevel.valid) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;

        }
      }else {
        return false;
      }
    },
    validAdditional(){
      this.errors = {}
          const validAdditionalCountry = validateSelect(this.form.additionalCountry, "Country");
          this.errors.additionalCountry = validAdditionalCountry.error;

          const validAdditionalState = validateSelect(this.form.additionalState, "State");
          this.errors.additionalState= validAdditionalState.error;

          const validAdditionalProvince = validateName(this.form.Province, "Province");
          this.errors.Province = validAdditionalProvince.error;

          const validAdditionalSsn = validateSsnNumber(this.form.socialSecurityNumber, "Social Security Number");
          this.errors.socialSecurityNumber= validAdditionalSsn.error;

          const validAdditionalGovtId = validateSelect(this.form.govtId, "Government Id Type");
          this.errors.govtId= validAdditionalGovtId.error;

        const validAdditionalGovtIdNum = validateName(this.form.govtIdNum, "Government Id Number");
        this.errors.govtIdNum= validAdditionalGovtIdNum.error;

          const validAdditionalMotherName = validateName(this.form.motherName, "Mother Name");
          this.errors.motherName= validAdditionalMotherName.error;

          const validAdditionalFatherName = validateName(this.form.fatherName, "Father Name");
          this.errors.fatherName= validAdditionalFatherName.error;

          const validAdditionalYearUs = validateName(this.form.yearUs, "Years In Us");
          this.errors.yearUs= validAdditionalYearUs.error;
         if(validAdditionalCountry.valid && validAdditionalState.valid && validAdditionalProvince.valid && validAdditionalSsn.valid && validAdditionalGovtId.valid && validAdditionalGovtIdNum.valid && validAdditionalMotherName.valid && validAdditionalFatherName.valid && validAdditionalYearUs.valid){
           return true;
         }else {
           return false;
         }

    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    countryChange(){
      this.form.state='';
      if(this.form.country == 'USA'){
        this.usaState=true;
      }else{
        this.usaState=false;
      }
      let countryObj = this.listOfCountries.find(item => item.country === this.form.country);
      this.form.country_code = countryObj?.code;
    },
    criminalCountryChange(){
      if(this.form.criminalCountry == 'USA'){
        this.usaCriminalState=true;
      }else{
        this.usaCriminalState=false;
      }
    },
    additionalChange(){
      this.form.additionalState = '';
      if(this.form.additionalCountry == 'USA'){
        this.usaAdditionalState=true;
      }else{
        this.usaAdditionalState=false;
      }
      let countryObj = this.listOfCountries.find(item => item.country === this.form.additionalCountry);
      this.form.additionalCountryCode = countryObj?.code;
    },
    stripeTokenCreated(res){
      this.stripeError = "";
      this.stripeToken = res.id;
      this.paymentForm.stripeToken = res.id;
      this.paymentApi();
      // document.getElementById('app').scrollIntoView();
    },
    captureStripeErrors(errors){
      this.stripeError = errors;
      console.log("===========>",errors);
      this.showLoader=false;
    },
    stripeFormChanges(obj){
      this.stripeValidate = false;
      if(obj.complete){
        this.stripeError = "";
        this.stripeValidate = true;
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide();
      this.cancelBackground();
    },
    toggleModal() {
      this.$refs['international-modal'].hide();
      this.saveBackground();
    },
    showInternationalModal() {
      this.internationalAddressNext=false;
      this.$refs['internation-modal'].show();

    },
    hideInternationalModal() {
      this.$refs['internation-modal'].hide();
    },
    cancelBackground() {
      axios.post(this.basePath + 'api/member/cancel_back_ground_screen', this.bgsId, {headers: this.memberHeaders})
          .then((res) => {
            if (res.data.status == 'success') {
              window.location.reload();
            } else {
              return;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            this.button.nextStepButton = 'Next Step';
            this.showLoader=false;
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
    saveBackground() {
      axios.post(this.basePath + 'api/member/save_back_ground_screen', this.bgsId, {headers: this.memberHeaders})
          .then((res) => {
            if (res.data.status == 'success') {
              window.location.reload();
            } else {
              return;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            this.button.nextStepButton = 'Next Step';
            this.showLoader=false;
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
    formatlongDate(datevalue){
      var date = moment(datevalue);
      if(date.isValid()){
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    interCountryChange(){
      this.form.interState = '';
      if(this.form.internatCountry == 'USA'){
        this.usaInterState=true;
      }else{
        this.usaInterState=false;
      }
      let countryObj = this.listOfCountries.find(item => item.country === this.form.internatCountry);
      this.form.internatCountryCode = countryObj?.code;
    },
    validInter(){
      this.errors = {}
          const validInterCounrty = validateSelect(this.form.internatCountry, "Country");
          this.errors.internatCountry = validInterCounrty.error;

          const validInterCity = validateName(this.form.interCity, "City");
          this.errors.interCity = validInterCity.error;

          const validInterAddr1 = validateName(this.form.interAddrLine1, "Address Line 1");
          this.errors.interAddrLine1 = validInterAddr1.error;

          const validInterState = validateStateName(this.form.interState, "State");
          this.errors.interState = validInterState.error;

          const validInterZip = (this.form.internatCountry != 'USA')?validateCanadaZip(this.form.interZip):validateZip(this.form.interZip);
          this.errors.interZip = validInterZip.error;

          if (validInterCounrty.valid && validInterCity.valid && validInterAddr1.valid && validInterState.valid && validInterZip.valid) {
            return true;
          } else {
            return false;
          }
    },
    internationalAddresses(){
      if(this.form.internationalAddr == 1){
        this.internationalAddressNext=true;
      }else if(this.form.internationalAddr == 2){
        this.internationalAddressNext=false;
      }
      return false;
    },
    applyCoupon() {
            let regData = {};
            regData.price = this.fees.fee;
            regData.coupon_code = this.couponApplied.coupon_code;
            regData.coupon_type_id = 3;
            this.button.coupon = 'Applying Coupon...';
            this.couponErrors = {};
            console.log('---------------');
            console.log(regData);
            axios.post(this.basePath+'api/check_coupon', regData)
                .then((res) => {
                  console.log(res.data);
                    if(res.data.status == 'success') {
                        this.couponApplied.status = 1;
                        this.couponApplied.amount = parseFloat(res.data.data.value).toFixed(2);
                        this.couponApplied.text = res.data.data.text+' off membership ('+this.couponApplied.coupon_code+')';
                        this.couponErrors.success = res.data.message;
                        this.paymentForm.coupon_id= res.data.data.id;
                        this.paymentForm.discount= res.data.data.value;
                        this.calculateTotals();
                    } else {
                        this.couponErrors.error = res.data.message;
                        this.couponApplied.status = 0;
                        this.couponApplied.amount = 0.00;
                        this.couponApplied.text = '';
                        this.calculateTotals();
                    }
                    this.button.coupon = 'Apply Coupon';
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    this.button.coupon = 'Apply Coupon';
                    return false;
                }).finally(() => {
            });
        },
        removeCoupon(){
            this.couponApplied = {
                status: 0,
                coupon_code: '',
                amount: 0.00,
                text: ''
            };
            this.couponErrors = {};
            this.calculateTotals();
        },
    applyNew() {
      this.backgroundScreen = null;
      this.internationalBackgroundScreen = null;
      this.wiz_step = 1;
      this.form = {
        lived_status: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            phone: '',
            email: '',
            dob: '',
            ssn: '',
            mother_maiden_name: '',
            address_type: 1,
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            country: 'USA',
            state_specific_work: '',
            state_specific_home: '',
            signature_name: '',
            type: 'Background Screening',
            fee: 30.00,
            processingFee: 0.00,
            grand_total: 0.00,
            processingFeePercent: 3,
            seeking_prospective_first: '',
            seeking_prospective_second: '',
            resident_first: '',
            resident_second: '',
            background_screening_type_id: 19
      };
    },
    getIpAddress() {
      axios.get('https://ipinfo.io/').then((res) => {
        this.ipAddressVal = res.data.ip;
      })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {});
    },
    calculateTotals()
    {
         let backGroundFee = this.fees.fee;
         if(this.couponApplied.status == 1) {
             backGroundFee = this.fees.fee - this.couponApplied.amount;
         }
        this.totals.sub_total = parseFloat(this.removeCommaFromNumber(backGroundFee));
        this.fees.processingFee = 0;
        if (this.subdomain == 'waterski') {
           this.fees.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.totals.sub_total))/100 * this.removeCommaFromNumber(this.fees.processingFeePercent)).toFixed(2);
         }
        this.fees.grand_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total)+this.removeCommaFromNumber(this.fees.processingFee)).toFixed(2);
            this.totals.sub_total = this.totals.sub_total < 0 ? 0.00 : parseFloat(this.totals.sub_total).toFixed(2);
            this.fees.grand_total = this.fees.grand_total < 0 ? 0.00 : this.fees.grand_total;
        this.paymentForm.amount = this.fees.grand_total;
        if(this.fees.grand_total == 0.0){
            this.paymentForm.visible = false;
            this.paymentForm.auto_renew = 0;
        } else {
            this.paymentForm.visible = true;
            this.paymentForm.auto_renew = 1;
        }
    },
    loadWaiverData()
    {
      if(this.subdomain === "shooting") {
        this.waiverTypeData = [
          {type: 'ELECTRONIC SIGNATURE CONSENT', ncsi_document_id: 2},
          {type: 'Disclosure Regarding Background Investigation', ncsi_document_id: 3},
          {type: 'Summary of Your Rights', ncsi_document_id: 4},
          {type: 'NOTICE REGARDING BACKGROUND INVESTIGATION PURSUANT TO NEW YORK LAW', ncsi_document_id: 10},
          {type: 'San Francisco Fair Chance Ordinance', ncsi_document_id: 6},
          {type: 'Fair Chance Initiative for Hiring Ordinance', ncsi_document_id: 7},
          {type: 'NOTICE REGARDING BACKGROUND CHECKS PER CALIFORNIA LAW', ncsi_document_id: 8},
          {type: 'ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND INVESTIGATION', ncsi_document_id: 11},
          {type: 'Additional Screening Consent', ncsi_document_id: 12},
          {type: 'DISCLOSURE REGARDING INVESTIGATIVE CONSUMER REPORT', ncsi_document_id: 5},
          {type: 'Disclosure', ncsi_document_id: 1},
        ];
      } else {
        this.waiverTypeData = [
          {type: 'ELECTRONIC SIGNATURE CONSENT', ncsi_document_id: 2},
          {type: 'Disclosure Regarding Background Investigation', ncsi_document_id: 3},
          {type: 'Summary of Your Rights', ncsi_document_id: 4},
          {type: 'NOTICE REGARDING BACKGROUND INVESTIGATION PURSUANT TO NEW YORK LAW', ncsi_document_id: 10},
          {type: 'San Francisco Fair Chance Ordinance', ncsi_document_id: 6},
          {type: 'Fair Chance Initiative for Hiring Ordinance', ncsi_document_id: 7},
          {type: 'NOTICE REGARDING BACKGROUND CHECKS PER CALIFORNIA LAW', ncsi_document_id: 8},
          {type: 'ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND INVESTIGATION', ncsi_document_id: 11},
          {type: 'Additional Screening Consent', ncsi_document_id: 12},
          {type: 'DISCLOSURE REGARDING INVESTIGATIVE CONSUMER REPORT', ncsi_document_id: 5},
        ];
      }
    },
    waiverAdditionalDataChange(event)
    {
      let state_rule_minnesota_check = document.getElementById("state_rule_minnesota_check");
      let state_rule_oklahoma_check = document.getElementById("state_rule_oklahoma_check");
      this.waiverSign[this.waiver_index].additional_data = {
        'state_rule_minnesota_check': state_rule_minnesota_check.checked,
        'state_rule_oklahoma_check': state_rule_oklahoma_check.checked
      };
    },
    downloadDocs(id) {
      this.waiverDownloading = true;
      axios.get(this.basePath + 'api/ncsi-custom/doc-download/'+id, {headers:this.memberHeaders, responseType: 'arraybuffer'})
          .then(response => {
            this.waiverDownloading = false;
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'NCSI-Documents'+moment().valueOf()+'.pdf';
            link.target = "_blank";
            link.click();
            link.remove();
          }).catch((error)=>{
        console.log("error download");
        this.waiverDownloading = false;
      });
    },
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        this.errors.phone = '';
      }
    },
    loadOtherPrices(){
      const retry2 = setInterval(() => {
        let other_pricing = this.getSiteProps('general.other_pricing');
        if(other_pricing !== undefined){
          axios.get(this.basePath+"api/other-pricing/current-prices")
              .then(res => {
                this.prices = res.data.data;
                if(this.subdomain === 'shooting') {
                  if (this.prices?.background_screening) {
                    this.form.backgroundScreeningType = 1;
                  } else if (this.prices?.international_background_screening) {
                    this.form.backgroundScreeningType = 2;
                  }
                  this.backgroundScreeningType();
                }
                this.feeCalculate();
              })
              .catch(err => {
                console.log(err);
              })
          clearInterval(retry2);
        }
      }, 200);
    },
    loadCurrentDocuments() {
      axios.get(this.basePath+"api/ncsi-custom/current-docs", { headers: this.memberHeaders })
      .then(res => {
        this.waiverTypeData = res.data.data;
        for(let i=0;i<this.waiverTypeData.length;i++) {
          let obj = {
            ncsi_document_id: null,
            slug: null,
            type: '',
            name: '',
            agree_first: '',
            agree_second: '',
            initials: '',
            ssn: '',
            additional_data: null
          };
          this.waiverSign.push(obj);
        }
      })
    }
  },
  beforeMount() {
    this.loadCurrentDocuments();
  },
  mounted() {
    this.loadOtherPrices();
    setTimeout(this.getStateClubs, 1000)
    this.cardYears();
    this.loadMembersInfo();
    const retry = setInterval(() => {
      this.props_fields = this.getSiteProps('memberRegistration.individual.fields');
      if(this.props_fields) clearInterval(retry);
    }, 2000);

    // this.loadWaiverData();

    this.getIpAddress();
  }
};
</script>
<style scoped>
ul.listitems-ul {
    padding-left: 20px;
}
.ncsi-btn-selected{
  background: #05213e !important;
  border: 1px solid #05213e !important;
  color: #fff !important;
}
.bgs-wrap span.header-steps.completed {
  border-bottom: 4px solid #c8c8c8;
}
.bgs-wrap span.header-steps.current-item {
  border-bottom: 5px solid #d32f2e;
}
</style>
